export default function Footer() {
	return (
		<>
			<div className='flex flex-col text-center justify-center pt-10 text-gray-500 text-xs'>
				By using, accessing or using Altia Service(s) you agree to the
				Terms
			</div>
			<div className='text-center justify-center text-gray-500 text-xs'>
				of{' '}
				<a
					href='https://legal.altiacloud.com/legal/current/msa/'
					target='_blank'
					rel="noreferrer"
					className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-xs'
				>
					<span className=''>Altia's Master Services Agreement</span>
				</a>
			</div>
		</>
	);
}
