import UpdateProfileForm from '../../components/updateprofileform';
import Spinner from '../../components/spinner';
import { UserContext } from '../../containers/Home';
import { useContext } from 'react';

export default function Home() {
	const user: any = useContext(UserContext);

	return (
		<>
			<div className='w-full px-4 sm:px-6 md:px-8 py-4'>
				{user?.claims ? (
					<UpdateProfileForm
						data={{
							email: user?.claims?.claims?.email,
							firstName: user?.claims?.claims?.given_name,
							lastName: user?.claims?.claims?.family_name,
							...user?.claims?.claims,
						}}
					></UpdateProfileForm>
				) : (
					<div className='flex justify-center items-center pt-20'>
						<Spinner size='lg' color='blue' /> Loading your
						profile...
					</div>
				)}
			</div>
		</>
	);
}
