import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/header';
import SaveButton from '../components/saveButton';
import UserForm from '../components/userform';
import OtpDialog from '../components/otpdialog';
import Spinner from '../components/spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { formatDistance } from 'date-fns';
import moment from 'moment-timezone';
import ExternalLogin from '../components/externallogin';

function Signup() {
	const formSchema = Yup.object().shape({
		password: Yup.string()
			.required('Password is mandatory')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi,
				'Min 8 characters, 1 letter, 1 number and 1 special character'
			),
		confirmPassword: Yup.string()
			.required('Confirm Password is mandatory')
			.oneOf([Yup.ref('password')], 'Passwords does not match'),
		email: Yup.string()
			.email('Email is mandatory')
			.required('Email is mandatory'),
		firstName: Yup.string()
			.required('First name is mandatory')
			.min(2, 'First name must be at 2 char long'),
		lastName: Yup.string()
			.required('Last name is mandatory')
			.min(2, 'Last name must be at 2 char long'),
		company: Yup.string(),
		position: Yup.string(),
		workUnit: Yup.string().required('Work Unit is mandatory'),
		employeeId: Yup.string(),
		mobileNumber: Yup.string()
			.required('Mobile number is mandatory')
			.matches(
			/\+\d{10}/,
			'Enter mobile with country code (e.g. +614..)'
		),
	});

	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);
	const {
		register,
		handleSubmit,
		setValue,
		control,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
		defaultValues: {
			email: '',
			userTimezone: moment.tz.guess(),
		},
	});

	const query = new URLSearchParams(window.location.search);
	const returnUrl = query.get('ReturnUrl') || '/home';
	const stripeSessionId = query.get('stripe_session_id');
	const product = query.get('product');

	const navigate = useNavigate();

	const [isOtpOpen, setOtpIsOpen] = useState(false);
	const onEnterOtp = (otp: string) => {
		const formD = getValues();
		onLoginSubmit({
			...formD,
			otpCode: otp,
		});
	};

	const onIgnoreOtp = () => {
		const formD = getValues();
		onLoginSubmit({
			...formD,
			otpCode: '',
			skipOtpVerification: true,
		});
	};

	const onResendOtp = () => {
		setSaving(true);
		setError('');
		setOtpIsOpen(false);
		const formD = getValues();
		axios
			.post(`/api/auth/ResendOtp`, formD)
			.then((response: any) => {
				setSaving(false);
				setOtpIsOpen(true);
			})
			.catch((error) => {
				setSaving(false);
				setOtpIsOpen(true);
				setError('Error occured. Unable to send OTP');
			});
	};

	const onLoginSubmit = (data: any) => {
		setSaving(true);
		setOtpIsOpen(false);
		axios
			.post(`/api/auth/signup`, {
				...data,
				stripeSessionId,
				product,
				returnUrl,
			})
			.then((response: any) => {
				setSaving(false);
				if (response.data.requireOtp) {
					setOtpIsOpen(true);
				} else {
					setOtpIsOpen(false);
					if (response.data.requiredConfirmation) {
						setError('');
						navigate('/confirmemail', { replace: false });
					} else {
						window.location.href = response.data.returnUrl;
					}
				}
			})
			.catch((error) => {
				setSaving(false);
				if (error?.response?.data?.otpCode) {
					setError(error?.response?.data?.otpCode[0]);
					setOtpIsOpen(true);
					return;
				}
				if (error?.response?.data?.email) {
					setError(error?.response?.data?.email[0]);
					return;
				}
				if (error?.response?.data?.password) {
					setError(error?.response?.data?.password[0]);
					return;
				}
				setError('Error occured. Unable to register user');
			});
	};

	const [signedUpSubscription, setSignedUpSubscription] = useState(null);
	const [loadingSubscription, setLoadingSubscription] = useState(false);
	useEffect(() => {
		if (!!stripeSessionId) {
			setLoadingSubscription(true);
			axios
				.get(`/api/auth/GetStripeSession?sessionId=${stripeSessionId}`)
				.then((response: any) => {
					setLoadingSubscription(false);
					setSignedUpSubscription(response.data);
					setValue('email', response.data.customerEmail);
				})
				.catch((error) => {
					setLoadingSubscription(false);
					setError(
						'There was someting wrong with Stripe checkout. Please try again'
					);
				});
		}
	}, []);

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<OtpDialog
				isOpen={isOtpOpen}
				isLoading={saving}
				error={error}
				closeModal={() => setOtpIsOpen(false)}
				onEnterOtp={onEnterOtp}
				onIgnoreOtp={onIgnoreOtp}
				resendOtp={onResendOtp}
			></OtpDialog>

			<Header />

			<div className='font-bold text-3xl py-8'>
				{!signedUpSubscription ? 'Sign up' : 'Complete Your Profile'}
			</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 mb-16 rounded-md w-full max-w-xl'>
				<div className='mt-2'>
					{loadingSubscription ? (
						<div className='flex justify-center pb-8'>
							<Spinner size='sm' color='blue' />
						</div>
					) : (
						<>
							{signedUpSubscription ? (
								<div className='text-normal pb-8'>
									Thanks for signing up for trial for{' '}
									<span className='font-bold'>
										{signedUpSubscription?.productName}
									</span>
									<div>
										After{' '}
										{formatDistance(
											new Date(
												signedUpSubscription?.trialEnd
											),
											new Date()
										)}{' '}
										your Credit Card will be automatically
										debited
									</div>
								</div>
							) : null}
						</>
					)}

					<ExternalLogin action='Sign up'></ExternalLogin>
					<div className='relative mt-10 h-px bg-gray-300'>
						<div className='absolute left-0 top-0 flex justify-center w-full -mt-2'>
							<span className='bg-white px-4 text-xs text-gray-500 '>
								Or continue with
							</span>
						</div>
					</div>

					<form
						onSubmit={handleSubmit(onLoginSubmit)}
						className='pt-10 pb-6'
					>
						<UserForm
							control={control}
							errors={errors}
							emailReadonly={false}
							showPassword={true}
							error={error}
							register={register}
							showTimezone={false}
							setValue={setValue}
						/>

						<div className='flex w-full mt-10'>
							<SaveButton
								disabled={!isValid}
								saving={saving}
								type='submit'
								className='flex items-center text-white justify-center focus:outline-none rounded-lg py-4 w-full bg-blue-600 hover:bg-blue-700'
							>
								Sign up
							</SaveButton>
						</div>
					</form>

					<div className='flex justify-center items-center'>
						<Link
							to={{
								pathname: '/',
								...(!!stripeSessionId &&
									!!product && {
										search: `?stripe_session_id=${stripeSessionId}&product=${product}`,
									}),
							}}
							className='link-default'
						>
							Already have an account? Sign in here
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Signup;