import Spinner from './spinner';

export default function SaveButton({
	saving,
	type,
	onClick,
	children,
	className,
	disabled,
}: any) {
	return (
		<button
			onClick={onClick}
			className={`bg-altia-blue text-white active:bg-altia-blue hover:bg-blue-800 font-bold uppercase text-xs px-8 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
    disabled:cursor-wait whitespace-nowrap
             disabled:bg-gray-300 ${className}`}
			type={type}
			disabled={saving || disabled}
		>
			{saving && <Spinner size='sm' color='white' />}
			{children}
		</button>
	);
}
