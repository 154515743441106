import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./spinner.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={'6Ld6KvkoAAAAANUu1DnhC-z4OAPgJmFYRHWnb-cW'}>
            <App />
        </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
