import { useFieldArray } from 'react-hook-form';
import cn from 'classnames';
import { TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';

function ListStringUpdate({
	title,
	fieldName,
	fields,
	append,
	remove,
	register,
	errors,
	helpText,
	generateUuid,
	watch,
}) {
	const fieldValues = watch(fieldName);
	return (
		<>
			<label className='block uppercase text-gray-600  font-bold  pt-8'>
				{title}
			</label>
			<label className='block  text-gray-600 italic  mb-2 text-sm'>
				{helpText}
			</label>
			<table className='items-center min-w-full bg-transparent border-collapse table-auto'>
				<thead>
					<tr>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap'></th>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0  font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap w-1'>
							<button
								type='button'
								className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 px-4  bg-indigo-600 hover:bg-indigo-700'
								onClick={() => {
									append({
										value: generateUuid ? uuidv4() : '',
										description: '',
										isNew: true,
									});
									if (generateUuid) {
										alert(
											'Generated secret will not be shown again once form is saved. \nPlease make sure you copy it'
										);
									}
								}}
							>
								Add
								<PlusCircleIcon className='flex-shrink-0 h-6 w-6 ml-2' />
							</button>
						</th>
					</tr>
				</thead>
				<tbody>
					{fields.map((item, index) => (
						<tr key={item.id} className='hover:bg-gray-50'>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0 p-4 py-6 text-left block text-ellipsis overflow-hidden whitespace-nowrap w-full'>
								<div
									className={
										'relative group ' +
										((errors as any)?.[fieldName]?.[index]
											?.value
											? 'isInvalid'
											: '')
									}
								>
									<input
										type={
											fieldValues[index].id &&
											generateUuid
												? 'password'
												: 'text'
										}
										placeholder={`Enter ${title}`}
										readOnly={generateUuid}
										{...register(
											`${fieldName}.${index}.value`
										)}
										className={cn('form-field', {
											'bg-gray-100': generateUuid,
										})}
									/>
									{fieldValues[index].isNew &&
									generateUuid ? (
										<input
											type='text'
											placeholder={`Enter Description`}
											{...register(
												`${fieldName}.${index}.description`
											)}
											className={cn(
												'form-field block mt-2'
											)}
										/>
									) : null}

									{fieldValues[index].id &&
									generateUuid &&
									fieldValues[index].description ? (
										<label className='block text-gray-600  mb-2 italic'>
											{fieldValues[index].description}
										</label>
									) : null}

									{!fieldValues[index].id && generateUuid ? (
										<label className='block text-gray-600  mb-2 italic'>
											Note: This value will not be shown
											again, make sure you copy it!!!
										</label>
									) : null}
								</div>
								{(errors as any)?.[fieldName]?.[index]?.value
									?.message && (
									<div className='relative bg-gray-300'>
										<div className='absolute left-0 top-0 flex justify-end w-full mt-1'>
											<span className=' text-red-500'>
												{
													(errors as any)?.[
														fieldName
													]?.[index]?.value?.message
												}
											</span>
										</div>
									</div>
								)}
							</td>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0  p-4 whitespace-nowrap'>
								<button
									type='button'
									className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 px-4 bg-red-600 hover:bg-red-700'
									onClick={() => remove(index)}
								>
									<TrashIcon className='flex-shrink-0 h-6 w-6' />
								</button>
							</td>
						</tr>
					))}
					{fields?.length === 0 ? (
						<>
							<div className='bg-white text-red-500 text-center py-6'>
								{(errors as any)?.[fieldName]?.message}
							</div>
						</>
					) : null}
				</tbody>
			</table>
		</>
	);
}

export default ListStringUpdate;
