import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/header';
import MicrosoftIcon from '../svgicons/microsoft';
import Footer from '../components/footer';
import Spinner from '../components/spinner';

function Login() {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingClient, setLoadingClient] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { isValid },
	} = useForm({ mode: 'onChange' });
	const [foundProviders, setFoundProviders] = useState<any[]>([]);

	const emailAddress = watch('email');
	const password = watch('password');
	const query = new URLSearchParams(window.location.search);
	const returnUrl = query.get('ReturnUrl') || '/home';
	const stripeSessionId = query.get('stripe_session_id');
	const product = query.get('product');

	const navigate = useNavigate();
	const [clientName, setClientName] = useState('');

	useEffect(() => {
		// check if we have email param
		const email = query.get('email');
		if (email && emailAddress) {
			console.log('loading with email address in the param');
			validateEmail(email);
		}
	}, [emailAddress]);

	useEffect(() => {
		// get client details
		if (returnUrl !== '/home') {
			console.log('loading configs for OIDC client');
			setLoadingClient(true);
			axios
				.post<any[]>(`/api/auth/GetClientDetails`, {
					returnUrl,
				})
				.then((response: any) => {
					setLoadingClient(false);
					setClientName(response?.data);
				})
				.catch((error) => {
					setLoadingClient(false);
					console.log('Error loading client details', error);
				});
		} else {
			// check if we have email param
			const email = query.get('email');
			if (email) {
				setValue('email', email);
			}
		}
	}, []);

	const nextClicked = () => {
		if (isValid) {
			validateEmail(emailAddress);
		}
	};

	const validateEmail = (email: string) => {
		setLoading(true);
		axios
			.post<any[]>(`/api/auth/GetLoginProviders`, {
				email,
				returnUrl,
			})
			.then((response) => {
				setLoading(false);
				if (response.data?.length === 0) {
					setFoundProviders([]);
					setShowPassword(true);
				} else if (response.data?.length === 1) {
					setFoundProviders([]);
					loginExternal(response.data[0].loginProvider);
				} else if (response.data?.length > 1) {
					setShowPassword(true);
					setFoundProviders(response.data);
				}
				setError('');
			})
			.catch((error) => {
				setError(
					error?.response?.data || 'Error occured, please try again'
				);
				setLoading(false);
			});
	};

	const onLoginSubmit = (data: any) => {
		if (isValid && password) {
			setLoading(true);
			axios
				.post(`/api/auth/login`, {
					email: emailAddress,
					password: password,
					returnUrl,
					stripeSessionId,
					product,
				})
				.then((response) => {
					setError('');
					setLoading(false);
					if (returnUrl === '/home') {
						// direct login to the ID
						navigate(returnUrl, { replace: false });
					} else {
						window.location.href = returnUrl;
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error.response?.data?.require2fa) {
						navigate(
							{
								pathname: '/signin2fa',
								search: `?returnUrl=${encodeURIComponent(
									error.response?.data?.returnUrl
								)}&rememberMe=${
									error.response?.data?.rememberMe
								}`,
							},
							{ replace: false }
						);
					} else if (error.response?.data?.requiredConfirmation) {
						navigate(
							{
								pathname: '/resendverification',
								search: `?returnUrl=${encodeURIComponent(
									error.response?.data?.returnUrl
								)}&rememberMe=${
									error.response?.data?.rememberMe
								}&email=${error.response?.data?.email}`,
							},
							{ replace: false }
						);
					} else if (error.response?.data?.lockedOut) {
						setError('Your account is locked out');
					} else {
						setError('Invalid username or password');
					}
				});
		}
	};

	const loginExternal = (provider: string) => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// dev code
			window.location.href = !stripeSessionId
				? `https://localhost:5001/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
						returnUrl
				  )}`
				: `https://localhost:5001/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
						returnUrl
				  )}&stripeSessionId=${stripeSessionId}&product=${encodeURIComponent(
						product
				  )}`;
		} else {
			window.location.href = !stripeSessionId
				? `/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
						returnUrl
				  )}`
				: `/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
						returnUrl
				  )}&stripeSessionId=${stripeSessionId}&product=${encodeURIComponent(
						product
				  )}`;
		}
	};

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='flex flex-col justify-center items-center pb-10'>
				<div className='font-bold text-3xl'>
					Sign in to your account
				</div>

				<div className='pt-3'>
					<span className=''></span>
					Or
					<Link
						to={{
							pathname: '/signup',
							...(!!stripeSessionId &&
								!!product && {
									search: `?stripe_session_id=${stripeSessionId}&product=${product}`,
								}),
						}}
					>
						<a className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-sm'>
							<span className='ml-2'>
								Sign up for an Altia Identity now
							</span>
						</a>
					</Link>
				</div>
			</div>

			<div className='flex flex-col bg-white shadow-lg px-4 sm:px-6 md:px-8 lg:px-10 py-16 rounded-lg w-full max-w-md'>
				<div className='mt-2'>
					{/* <div className='pb-3 text-center text-gray-800 flex items-center justify-center uppercase text-xl'>
						{loadingClient ? (
							<Spinner size='sm' color='blue' />
						) : (
							<>{clientName ? clientName : ''}</>
						)}
					</div> */}
					<form onSubmit={handleSubmit(onLoginSubmit)}>
						{!showPassword ? (
							<div className='flex flex-col mb-6'>
								<label
									htmlFor='email'
									className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600'
								>
									E-Mail Address:
								</label>
								<div className='relative'>
									<input
										type='email'
										{...register('email', {
											pattern: {
												value: /\S+@\S+\.\S+/,
												message:
													'Entered value does not match email format',
											},
										})}
										onKeyPress={(event) => {
											if (event.key === 'Enter') {
												nextClicked();
											}
										}}
										className='text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'
									/>
								</div>
							</div>
						) : null}

						{showPassword ? (
							<div className='flex flex-col mb-6 transition duration-150 ease-in'>
								<div className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600 flex flex-row justify-between py-4'>
									<div className='font-semibold text-altia-red'>
										{emailAddress}
									</div>
									<div>
										<button
											type='button'
											onClick={() => {
												setValue('password', null);
												setShowPassword(false);
												setError('');
											}}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4 text-altia-red cursor-pointer'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
												/>
											</svg>
										</button>
									</div>
								</div>

								{foundProviders.length === 0 ? (
									<>
										<div className='flex flex-col mb-2'>
											<label
												htmlFor='password'
												className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600'
											>
												Password:
											</label>
											<div className='relative'>
												<input
													type='password'
													{...register('password', {
														required: true,
													})}
													className='text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'
													placeholder='Password'
												/>
											</div>
										</div>
										<div className='flex flex-col pt-4'>
											<label className='inline-flex items-center cursor-pointer'>
												<input
													type='checkbox'
													className='form-checkbox border rounded text-blueGray-700 w-5 h-5 ease-linear transition-all duration-150 mr-2'
													{...register('rememberMe')}
												/>
												Remember Me
											</label>
										</div>
									</>
								) : (
									<>
										<div>
											{foundProviders.map((provider) => (
												<div
													key={provider.providerKey}
													className='w-full'
												>
													<button
														className='relative mt-4 border rounded-md py-2 text-sm text-gray-600 font-semibold hover:bg-gray-100  w-full'
														onClick={() =>
															loginExternal(
																provider.providerDisplayName
															)
														}
													>
														<span className='absolute left-0 top-0 flex items-center justify-center h-full w-10 text-blue-500'>
															<i className='fab fa-facebook-f'></i>
														</span>
														<span>
															Continue with{' '}
															{
																provider.providerDisplayName
															}
														</span>
													</button>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						) : null}

						{showPassword && foundProviders.length === 0 ? (
							<div className='flex items-center mb-6 -mt-12'>
								<div className='flex ml-auto'>
									<Link
										to={{
											pathname: '/forgotpassword',
											...(!!stripeSessionId &&
												!!product && {
													search: `?stripe_session_id=${stripeSessionId}&product=${product}`,
												}),
										}}
									>
										<a className='inline-flex text-xs sm:text-sm text-altia-red hover:text-red-700'>
											Forgot Your Password?
										</a>
									</Link>
								</div>
							</div>
						) : null}

						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-start w-full -mt-5'>
								<span className='bg-white text-xs text-altia-red'>
									{error}
								</span>
							</div>
						</div>
						<div className='flex w-full'>
							{!showPassword && (
								<button
									type='button'
									onClick={nextClicked}
									disabled={!isValid || !emailAddress}
									className={cn(
										'flex items-center text-white justify-center focus:outline-none text-sm sm:text-base rounded-md py-2 w-full transition duration-150 ease-in',
										{
											'bg-altia-blue hover:bg-blue-700':
												isValid && emailAddress,
											'bg-gray-400':
												!isValid || !emailAddress,
										}
									)}
								>
									{loading ? (
										<Spinner size='sm' color='white' />
									) : null}
									<span className='mr-2 uppercase'>
										Continue
									</span>
								</button>
							)}

							{showPassword && foundProviders.length === 0 && (
								<button
									type='submit'
									disabled={!isValid}
									className={cn(
										'flex items-center text-white justify-center focus:outline-none rounded py-2 w-full transition duration-150 ease-in',
										{
											'bg-blue-600 hover:bg-blue-700':
												isValid,
											'bg-gray-400': !isValid,
										}
									)}
								>
									{loading ? (
										<Spinner size='sm' color='white' />
									) : null}
									<span className='mr-2 uppercase'>
										Login
									</span>
								</button>
							)}
						</div>
					</form>
				</div>

				<div className='relative mt-10 h-px bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-center w-full -mt-2'>
						<span className='bg-white px-4 text-xs text-gray-500'>
							Or continue with
						</span>
					</div>
				</div>

				<div className='flex justify-between space-x-2'>
					<button
						className='relative mt-8 border rounded-md py-2 text-sm text-white  font-semibold hover:bg-gray-100 flex justify-center w-36'
						onClick={() => loginExternal('Microsoft')}
					>
						<svg
							className='h-6 w-6 text-center'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 23 23'
						>
							<path fill='#f35325' d='M1 1h10v10H1z' />
							<path fill='#81bc06' d='M12 1h10v10H12z' />
							<path fill='#05a6f0' d='M1 12h10v10H1z' />
							<path fill='#ffba08' d='M12 12h10v10H12z' />
						</svg>
					</button>

					<button
						className='relative mt-8 border rounded-md py-2 text-sm font-semibold hover:bg-gray-100 flex justify-center w-36'
						onClick={() => loginExternal('Google')}
					>
						<svg
							className='h-6 w-6 text-center'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 256 262'
						>
							<path
								fill='#4285F4'
								d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
							/>
							<path
								fill='#34A853'
								d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
							/>
							<path
								fill='#FBBC05'
								d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
							/>
							<path
								fill='#EB4335'
								d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
							/>
						</svg>
					</button>

					<button
						className='relative mt-8 border rounded-md py-2 text-sm text-gray-600  font-semibold hover:bg-gray-100 flex justify-center w-36'
						onClick={() => loginExternal('Microsoft')}
					>
						Government
					</button>
				</div>
			</div>

			<div className='mb-16'>
				<Footer />
			</div>
		</div>
	);
}

export default Login;
