import { useState } from 'react';
import Spinner from '../components/spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../components/header';

function ResetPassword() {
	const formSchema = Yup.object().shape({
		email: Yup.string()
			.email('Email is mandatory')
			.required('Email is mandatory'),
		password: Yup.string()
			.required('Password is mandatory')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi,
				'Min 8 characters, 1 letter, 1 number and 1 special character'
			),
		confirmPassword: Yup.string()
			.required('Confirm Password is mandatory')
			.oneOf([Yup.ref('password')], 'Passwords does not match'),
	});

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { isValid, errors },
		watch,
	} = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

	const query = new URLSearchParams(window.location.search);
	const code = query.get('code');
	const stripeSessionId = query.get('stripe_session_id');
	const product = query.get('product');

	const password = watch('password');
	const confirmPassword = watch('confirmPassword');

	const onLoginSubmit = (data: any) => {
		if (isValid) {
			setLoading(true);
			axios
				.post(`/api/auth/ResetPassword`, {
					...data,
					code,
					stripeSessionId,
					product,
				})
				.then((response: any) => {
					setLoading(false);
					setError('');
					setDone(true);
				})
				.catch((error) => {
					setLoading(false);
					if (error?.response?.data?.error) {
						setError(error?.response?.data?.error[0]);
						return;
					}

					setError('Error occured. Please try again');
				});
		}
	};

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='font-bold text-3xl py-8'>Reset Password</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-16 mb-16 rounded-md w-full max-w-md'>
				{!done ? (
					<div className=''>
						<form onSubmit={handleSubmit(onLoginSubmit)}>
							<div className='flex flex-col mb-8'>
								<div
									className={
										'relative group ' +
										((errors as any)?.email
											? 'isInvalid'
											: '')
									}
								>
									<input
										type='email'
										placeholder='Email address'
										{...register('email', {
											required: true,
											pattern: {
												value: /\S+@\S+\.\S+/,
												message:
													'Entered value does not match email format',
											},
										})}
										className='form-field'
									/>
								</div>
							</div>

							{(errors as any)?.email?.message && (
								<div className='relative bg-gray-300'>
									<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
										<span className='bg-white text-xs text-red-500'>
											{(errors as any)?.email?.message}
										</span>
									</div>
								</div>
							)}

							<div className='flex flex-col mb-8'>
								<div
									className={
										'relative group ' +
										((errors as any)?.password
											? 'isInvalid'
											: '')
									}
								>
									<input
										type='password'
										placeholder='New password'
										{...register('password', {
											required: true,
											minLength: 1,
										})}
										className='form-field'
									/>
								</div>
							</div>

							{(errors as any)?.password?.message && (
								<div className='relative bg-gray-300'>
									<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
										<span className='bg-white text-xs text-red-500'>
											{(errors as any)?.password?.message}
										</span>
									</div>
								</div>
							)}

							<div className='flex flex-col mb-8'>
								<div
									className={
										'relative group ' +
										((errors as any)?.confirmPassword
											? 'isInvalid'
											: '')
									}
								>
									<input
										type='password'
										placeholder='Confirm new password'
										{...register('confirmPassword', {
											required: true,
											minLength: 1,
										})}
										className='form-field'
									/>
								</div>
							</div>

							{(errors as any)?.confirmPassword?.message && (
								<div className='relative bg-gray-300'>
									<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
										<span className='bg-white text-xs text-red-500'>
											{
												(errors as any)?.confirmPassword
													?.message
											}
										</span>
									</div>
								</div>
							)}

							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-start w-full -mt-1'>
									<span className='bg-white text-xs text-red-500'>
										{error}
									</span>
								</div>
							</div>

							<div className='flex w-full mt-10'>
								<button
									type='submit'
									disabled={
										!isValid || password !== confirmPassword
									}
									className={cn('btn-default', {
										'bg-blue-600 hover:bg-blue-700':
											isValid &&
											password === confirmPassword,
										'bg-gray-400':
											!isValid ||
											password !== confirmPassword,
									})}
								>
									{loading ? (
										<Spinner size='sm' color='white' />
									) : null}
									<span className='mr-2 uppercase'>
										Reset Password
									</span>
								</button>
							</div>
						</form>
					</div>
				) : (
					<div className='mt-2'>
						<div className='pb-6 text-center uppercase text-xl'>
							Reset Password
						</div>
						<div className='pb-6 text-gray-500 text-center  text-sm'>
							Your password has been successfully reset
						</div>
					</div>
				)}

				<div className='flex justify-center items-center pt-4'>
					<Link to='/'>
						<a className='link-default'>
							<span className='ml-2'>Go back to Login page</span>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
