import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveButton from './saveButton';
import * as Yup from 'yup';
import Countdown from 'react-countdown';

const OtpDialog: React.FC<{
	isOpen: boolean;
	isLoading?: boolean;
	error?: string;
	closeModal: () => void;
	onEnterOtp?: (otp: string) => void;
	onIgnoreOtp?: () => void;
	resendOtp?: () => void;
}> = ({
	isOpen,
	error,
	isLoading,
	resendOtp,
	closeModal,
	onEnterOtp,
	onIgnoreOtp,
}) => {
	const onSubmit = async (data: any) => {
		if (onEnterOtp) onEnterOtp(data.otpCode);
	};

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => null}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='text-3xl font-medium leading-6 text-gray-900 px-4 pt-5'
									>
										Enter OTP
									</Dialog.Title>

									<div className='pt-6'>
										<OtpForm
											onSubmit={onSubmit}
											resendOtp={resendOtp}
											error={error}
											onIgnoreOtp={onIgnoreOtp}
											onCancel={closeModal}
											isLoading={isLoading}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
export default OtpDialog;

function OtpForm({
	onSubmit,
	error,
	onIgnoreOtp,
	resendOtp,
	onCancel,
	isLoading,
}) {
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return (
				<SaveButton
					saving={isLoading}
					onClick={resendOtp}
					type='button'
					className='bg-gray-200 text-white active:bg-blue-600  font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
			disabled:cursor-wait whitespace-nowrap
							 disabled:bg-gray-300'
				>
					Resend
				</SaveButton>
			);
		} else {
			return (
				<button
					type='button'
					className='bg-gray-200 text-black font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
				disabled:cursor-wait whitespace-nowrap
								 disabled:bg-gray-300'
					disabled={true}
				>
					Retry in {seconds}(s)
				</button>
			);
		}
	};

	const formSchema = Yup.object().shape({
		otpCode: Yup.string()
			.required('OTP is mandatory')
			.min(6, 'OTP must be at least 6 char long')
			.max(10, 'OTP must be at less than 10 char long'),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='relative flex flex-col min-w-0 break-words w-full border-0'>
				<div className='flex-auto py-3'>
					<div className='flex flex-wrap'>
						<div className='w-full px-4'>
							<div className='relative w-full mb-3'>
								<label
									className='block text-gray-600 mb-2'
									htmlFor='grid-password'
								>
									Enter the One Time Password that was sent to
									your mobile number.
								</label>
								<input
									type='text'
									maxLength={8}
									minLength={6}
									className='border px-2 py-2 my-4 placeholder-gray-300 text-gray-600 bg-white rounded text-sm w-full shadow focus:outline-none focus:ring mx-auto ease-linear transition-all duration-150'
									{...register('otpCode')}
								/>
							</div>

							{(errors as any)?.otpCode?.message && (
								<div className='relative bg-gray-300'>
									<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
										<span className='bg-white text-xs text-red-500'>
											{(errors as any)?.otpCode?.message}
										</span>
									</div>
								</div>
							)}

							{!!error ? (
								<div className='relative'>
									<div className='flex justify-start w-full'>
										<span className='bg-white text-red-500'>
											{error}
										</span>
									</div>
									<button
										type='button'
										onClick={onIgnoreOtp}
										className='inline-flex items-center font-bold hover:underline text-sm pt-3'
									>
										Skip for now
									</button>
								</div>
							) : null}
						</div>
					</div>
					<div className='w-full px-4 pt-6'>
						<div className='relative w-full text-right'>
							<Countdown
								date={Date.now() + 25000}
								renderer={renderer}
							/>

							<button
								onClick={onCancel}
								className='bg-gray-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
    disabled:cursor-wait whitespace-nowrap
             disabled:bg-gray-300'
								type='button'
							>
								Cancel
							</button>
							<SaveButton
								saving={isLoading}
								type='submit'
								className='bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
								disabled:cursor-wait whitespace-nowrap
												 disabled:bg-gray-300'
							>
								Submit
							</SaveButton>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}
