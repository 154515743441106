import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { CodeBracketSquareIcon } from '@heroicons/react/24/outline';

const ClientsTable: React.FC<{
	data: any[];
}> = ({ data }) => {

	return (
		<>
			<table className='items-center w-full bg-transparent border-collapse table-auto max-w-5xl'>
				<thead>
					<tr>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap'>
							Client Id
						</th>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0  font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap w-1'>
							Client Name
						</th>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0  font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap w-1'>
							Type
						</th>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0  font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap w-1'>
							URL
						</th>
						<th className='px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0  font-semibold text-left bg-gray-50 text-gray-500 border-gray-100 whitespace-nowrap w-1'>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((job) => (
						<tr
							key={job.id}
							className='hover:bg-gray-200 hover:cursor-pointer'
						>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0 p-4 text-left block text-ellipsis overflow-hidden whitespace-nowrap'>
								<Link
									to={`/home/admin/clients/${job.clientId}`}
									className=' underline'
								>
									{job.clientId}
								</Link>
							</td>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0  p-4 whitespace-nowrap'>
								{job.clientName}
							</td>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0  p-4 whitespace-nowrap'>
								<div className='flex flex-row items-center space-x-1'>
									{job.allowedGrantTypes?.filter(
										(x) => x === 'client_credentials'
									)?.length > 0 ? (
										<><CodeBracketSquareIcon className='flex-shrink-0 h-7 w-7'/> Integration</>
									) : (
										<>
											<img
												src={job.properties?.icon}
												className='w-6 hover:cursor-pointer '
												alt={job.properties?.sku}
											/>
											<span>{job.properties?.sku}</span>
										</>
									)}
								</div>
							</td>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0  p-4 whitespace-nowrap'>
								{job.properties?.launchUrl ? (
									<a
										className='inline-flex text-blue-500 hover:text-blue-700'
										href={job.properties?.launchUrl}
										target='_blank'
										rel='noreferrer'
									>
										{job.properties?.launchUrl}
									</a>
								) : null}
							</td>
							<td className='border-t-0 px-6 align-middle border-l-0 border-r-0  p-4 whitespace-nowrap'>
								<Link
									to={`/home/admin/clients/${job.clientId}`}
									className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 px-4 w-full bg-blue-600 hover:bg-blue-700'
								>
									Edit
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default ClientsTable;
