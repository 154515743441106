import { useState, Fragment, useEffect } from 'react';
import { formatDistance } from 'date-fns';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Spinner from '../components/spinner';
import SaveButton from '../components/saveButton';
import UserForm from '../components/userform';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import OtpDialog from '../components/otpdialog';
import moment from 'moment-timezone';

function SignupForm({ externalClaims, warning }) {
	const formSchema = Yup.object().shape({
		email: Yup.string()
			.email('Email is mandatory')
			.required('Email is mandatory'),
		firstName: Yup.string()
			.required('First name is mandatory')
			.min(2, 'Password must be at 2 char long'),
		lastName: Yup.string()
			.required('Last name is mandatory')
			.min(2, 'Password must be at 2 char long'),
		company: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Company must be at 2 char long'),
		position: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Position must be at 2 char long'),
		workUnit: Yup.string()
			.required('Work Unit is mandatory')
			.min(2, 'Work Unit must be at 2 char long'),
		employeeId: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Employee ID must be at 2 char long'),
		mobileNumber: Yup.string()
			.required('Mobile number is mandatory')
			.matches(
			/\+\d{10}/,
			'Enter mobile with country code (e.g. +614..)'
		),
	});
	const [error, setError] = useState('');

	const {
		register,
		handleSubmit,
		getValues,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
		defaultValues: {
			...externalClaims,
			userTimezone: moment.tz.guess(),
		},
	});

	const query = new URLSearchParams(window.location.search);
	const returnUrl = query.get('ReturnUrl') || '/home';
	const stripeSessionId = query.get('stripe_session_id');
	const betaProduct = query.get('betaProduct');
	const product = query.get('product');
	const [showDialog, setShowDialog] = useState(false);
	const [continueUrl, setContinueUrl] = useState(null);

	const [saving, setSaving] = useState(false);

	const [isOtpOpen, setOtpIsOpen] = useState(false);
	const onEnterOtp = (otp: string) => {
		const formD = getValues();
		onCreateAccountSubmit({
			...formD,
			otpCode: otp,
		});
	};

	const onIgnoreOtp = () => {
		const formD = getValues();
		onCreateAccountSubmit({
			...formD,
			otpCode: '',
			skipOtpVerification: true,
		});
	};

	const onResendOtp = () => {
		setSaving(true);
		setError('');
		setOtpIsOpen(false);
		const formD = getValues();
		axios
			.post(`/api/auth/ResendOtp`, formD)
			.then((response: any) => {
				setSaving(false);
				setOtpIsOpen(true);
			})
			.catch((error) => {
				setSaving(false);
				setOtpIsOpen(true);
				setError('Error occured. Unable to send OTP');
			});
	};

	const onCreateAccountSubmit = (data: any) => {
		const toSend = {
			...data,
			stripeSessionId,
			betaProduct,
			product,
			returnUrl,
		};
		// console.log("🚀 ~ file: CreateAccount.tsx:116 ~ onCreateAccountSubmit ~ toSend:", toSend)
		// return;

		setSaving(true);
		setOtpIsOpen(false);
		axios
			.post(`/api/auth/CompleteExternalProfile`, toSend)
			.then((response: any) => {
				setSaving(false);
				if (response.data.requireOtp) {
					setOtpIsOpen(true);
				} else {
					setOtpIsOpen(false);
					if (response.data.returnUrl) {
						setContinueUrl(response.data.returnUrl);
						setShowDialog(true);
					} else {
						setError('Error occured. Unable to register user');
					}
				}
			})
			.catch((error) => {
				setSaving(false);
				if (error?.response?.data?.otpCode) {
					setError(error?.response?.data?.otpCode[0]);
					setOtpIsOpen(true);
					return;
				}
				setError('Error occured. Unable to register user');
			});
	};

	const onContinue = () => {
		window.location.href = continueUrl;
	};

	return (
		<div className=''>
			<OtpDialog
				isOpen={isOtpOpen}
				isLoading={saving}
				error={error}
				closeModal={() => setOtpIsOpen(false)}
				onEnterOtp={onEnterOtp}
				onIgnoreOtp={onIgnoreOtp}
				resendOtp={onResendOtp}
			></OtpDialog>

			<Transition appear show={showDialog} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={onContinue}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='text-3xl font-medium leading-6 text-gray-900 px-4 pt-5 text-center mb-8'
									>
										Thank you!
									</Dialog.Title>

									<div className='py-3 my-4 px-3 '>
										<p>
											Your profile is now complete. Visit
											the Connected Apps page to access
											your Altia Services. .
										</p>
									</div>
									<div className='py-3 my-4 px-3 '>
										<p>
											<button
												type='button'
												className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 w-full bg-blue-600 hover:bg-blue-700'
												onClick={onContinue}
											>
												<span className='mr-2'>
													Continue
												</span>
											</button>
										</p>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>

			<div className=' text-gray-500 text-sm'>
				We'll use your details from{' '}
				<span className='font-semibold text-altia-red'>
					{' '}
					{externalClaims?.provider}
				</span>{' '}
				to create an account:
			</div>

			{!!warning ? (
				<div
					className='bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-8'
					role='alert'
				>
					<p className='font-bold'>Warning</p>
					<p>{warning}</p>
				</div>
			) : null}

			<form
				onSubmit={handleSubmit(onCreateAccountSubmit)}
				className='pt-8 pb-6'
			>
				<UserForm
					control={control}
					errors={errors}
					emailReadonly={true}
					showPassword={false}
					error={error}
					register={register}
					showTimezone={false}
					setValue={setValue}
				/>

				<div className=' text-gray-500 text-sm'>
					By clicking 'Create your account' you have read and agree to{' '}
					<a
						href='https://legal.altiacloud.com/legal/current/msa/'
						target='_blank'
						rel='noreferrer'
						className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-xs'
					>
						<span className=''>
							Altia's Master Services Agreement
						</span>
					</a>{' '}
					and Agreement Reference Material all available at{' '}
					<a
						href='https://legal.altiacloud.com/'
						target='_blank'
						rel='noreferrer'
						className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-xs'
					>
						<span className=''>Altia Trust Center</span>
					</a>
				</div>
				<div className='flex w-full mt-10'>
					<SaveButton
						disabled={!isValid}
						saving={saving}
						type='submit'
						className='w-full'
					>
						Create your account
					</SaveButton>
				</div>
			</form>
		</div>
	);
}

function Signup() {
	const query = new URLSearchParams(window.location.search);
	const stripeSessionId = query.get('stripe_session_id');
	const email = query.get('email');
	const betaProduct = query.get('betaProduct');
	const [externalClaims, setExternalClaims] = useState(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [warning, setWarning] = useState('');

	const [signedUpSubscription, setSignedUpSubscription] = useState(null);
	const [loadingSubscription, setLoadingSubscription] = useState(false);

	useEffect(() => {
		if (!!stripeSessionId) {
			setLoadingSubscription(true);
			axios
				.get(`/api/auth/GetStripeSession?sessionId=${stripeSessionId}`)
				.then((response: any) => {
					setLoadingSubscription(false);
					setSignedUpSubscription(response.data);
				})
				.catch((error) => {
					setLoadingSubscription(false);
					setError(
						'There was someting wrong with Stripe checkout. Please try again'
					);
				});
		}
	}, []);

	useEffect(() => {
		// get claims
		axios
			.get(`/api/auth/GetExtenalClaims`)
			.then((response: any) => {
				setLoading(false);
				if (response.data) {
					setExternalClaims(response.data);
					if (!!betaProduct && email !== response.data.email) {
						setWarning(
							`Email you used to login to ${query.get(
								'fromProvider'
							)} is different from what you registered with Altia Beta Program. We will use ${
								response.data.email
							} instead`
						);
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				setError('Error occured. Unable to login');
			});
	}, []);

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			{betaProduct ? (
				<div className='font-bold text-2xl text-center'>
					Thanks for signing up for {query.get('betaProduct')} Beta
				</div>
			) : null}

			<div className='font-bold text-3xl py-8'>Complete your profile</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 mb-16 rounded-md w-full max-w-xl'>
				{loadingSubscription ? (
					<div className='flex justify-center pb-8'>
						<Spinner size='sm' color='blue' />
					</div>
				) : (
					<>
						{signedUpSubscription ? (
							<div className='text-normal pb-8'>
								Thanks for signing up for trial for{' '}
								<span className='font-bold'>
									{signedUpSubscription?.productName}
								</span>
								<div>
									After{' '}
									{formatDistance(
										new Date(
											signedUpSubscription?.trialEnd
										),
										new Date()
									)}{' '}
									your Credit Card will be automatically
									debited
								</div>
							</div>
						) : null}
					</>
				)}

				<div className='mt-2'>
					<div className=' text-gray-500 text-sm pb-8'>
						Complete all fields of the below Profile Registration
						form to ensure we can secure your account and contact
						you if required. You can always update your profile on
						the Profile page in your Altia Identity Account.
					</div>

					{!loading ? (
						<SignupForm
							externalClaims={externalClaims}
							warning={warning}
						/>
					) : (
						<>
							<Spinner size='sm' color='blue' />
						</>
					)}

					<div className='flex justify-center items-center'>
						<Link to='/' className='link-default'>
							Already have an account? Sign in here
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Signup;