import { Dialog, Transition } from '@headlessui/react'
import {Fragment, useContext, useState} from 'react'
import Spinner from "./spinner";
import {useForm} from "react-hook-form";
import ModalDigitConfirm from "./modaldigitconfirm";
import axios from "axios";
import {toast} from "react-toastify";
import {UserContext} from "../containers/Home";

export default function ModalChangeEmail(props) {
  const {isOpen, closeModal} = props;
  const user: any = useContext(UserContext);
  const { register, getValues, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    }
  });
  const [isOpenCodeInputModal, setIsOpenCodeInputModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleChangeEmail = () => {
    setSaving(true);
    axios
      .post(`/api/user/GenerateChangeEmailCode`, {
        email: getValues("email"),
        firstName: user?.claims?.claims?.given_name,
        lastName: user?.claims?.claims?.family_name,
      })
      .then((response) => {
        toast('A 6 digits code was sent to your email!');
        setIsOpenCodeInputModal(true);
        setSaving(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data || 'Invalid email address');
        setSaving(false);
      });
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <ModalDigitConfirm
                  isOpen={isOpenCodeInputModal}
                  closeModal={() => setIsOpenCodeInputModal(false)}
                  title="Enter code"
                  content="Please enter the code sent to your email"
                  data={getValues("email")}
                  closeParentModal={closeModal}
                />
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Change your email
                  </Dialog.Title>
                  <div className="w-full lg:w-12/12 my-3 ">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      className="border px-2 py-2 placeholder-gray-300 border-gray-300 text-gray-600 text-sm bg-white rounded focus:outline-none  w-full ease-linear transition-all duration-150"
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="mt-5 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex mr-4 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="inline-flex disabled:bg-gray-300 uppercase bg-blue-500 text-white content-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      disabled={saving}
                      onClick={handleChangeEmail}
                    >
                      <span>
                        {saving && <Spinner size="sm" color="white"/>}
                      </span>
                      OK
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
