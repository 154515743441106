import { useFieldArray } from 'react-hook-form';
import cn from 'classnames';
import ListStringUpdate from '../components/liststringupdate';

function ClientForm({ errors, register, error, watch, control }) {
	const isClientCredential = watch('isClientCredential');
	const requireClientSecret = watch('requireClientSecret');

	const {
		fields: clientSecrets,
		append: appendClientSecret,
		remove: removeClientSecret,
	} = useFieldArray({
		control,
		name: 'clientSecrets',
	});

	const {
		fields: redirectUris,
		append: appendredirectUris,
		remove: removeredirectUris,
	} = useFieldArray({
		control,
		name: 'redirectUris',
	});

	const {
		fields: allowedScopes,
		append: appendallowedScopes,
		remove: removeallowedScopes,
	} = useFieldArray({
		control,
		name: 'allowedScopes',
	});

	const {
		fields: allowedEmailDomains,
		append: appendallowedEmailDomains,
		remove: removeallowedEmailDomains,
	} = useFieldArray({
		control,
		name: 'allowedEmailDomains',
	});

	const {
		fields: allowedEmailAddresses,
		append: appendallowedEmailAddresses,
		remove: removeallowedEmailAddresses,
	} = useFieldArray({
		control,
		name: 'allowedEmailAddresses',
	});

	const {
		fields: postLogoutRedirectUris,
		append: appendpostLogoutRedirectUris,
		remove: removepostLogoutRedirectUris,
	} = useFieldArray({
		control,
		name: 'postLogoutRedirectUris',
	});

	return (
		<>
			<div className='flex flex-col mb-8'>
				<div
					className={
						'relative group ' +
						((errors as any)?.clientId ? 'isInvalid' : '')
					}
				>
					<label className='block uppercase text-gray-600  font-bold mb-2'>
						Application Id *
					</label>
					<input
						type='text'
						placeholder='Client Id'
						readOnly={true}
						{...register('clientId', {})}
						className={cn('form-field', {
							'bg-gray-100': true,
						})}
					/>
				</div>
			</div>
			{(errors as any)?.clientId?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white  text-red-500'>
							{(errors as any)?.clientId?.message}
						</span>
					</div>
				</div>
			)}
			<div className='flex flex-col mb-8'>
				<div
					className={
						'relative group ' +
						((errors as any)?.clientName ? 'isInvalid' : '')
					}
				>
					<label className='block uppercase text-gray-600  font-bold mb-2'>
						Application Name *
					</label>
					<input
						type='text'
						placeholder='Client Name'
						{...register('clientName', {})}
						className={cn('form-field', {
							'bg-gray-100': false,
						})}
					/>
				</div>
			</div>
			{(errors as any)?.clientName?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white  text-red-500'>
							{(errors as any)?.clientName?.message}
						</span>
					</div>
				</div>
			)}
			<div className='flex flex-col'>
				<div className='flex flex-col mb-8'>
					<label className='inline-flex items-center cursor-pointer'>
						<input
							type='checkbox'
							className='form-checkbox border rounded text-blueGray-700 w-5 h-8 ease-linear transition-all duration-150 mr-3'
							{...register('isClientCredential')}
						/>
						Is Machine to Machine integration?
					</label>
				</div>

				{!isClientCredential ? (
					<>
						<div className='flex flex-col py-4'>
							<div
								className={
									'relative group ' +
									((errors as any)?.launchUrl
										? 'isInvalid'
										: '')
								}
							>
								<label className='block uppercase text-gray-600 font-bold mb-2'>
									Application Launch Url
								</label>
								<input
									type='text'
									placeholder='App Url'
									{...register('launchUrl', {})}
									className={cn('form-field', {
										'bg-gray-100': false,
									})}
								/>
							</div>
						</div>
						{(errors as any)?.launchUrl?.message && (
							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-end w-full -mt-3'>
									<span className='bg-white  text-red-500'>
										{(errors as any)?.launchUrl?.message}
									</span>
								</div>
							</div>
						)}

						<div className='flex flex-col mb-8 w-1/2'>
							<div
								className={
									'relative group ' +
									((errors as any)?.productSku
										? 'isInvalid'
										: '')
								}
							>
								<label className='block uppercase text-gray-600  font-bold mb-2'>
									Application SKU *
								</label>
								<select
									{...register('productSku')}
									className='form-field appearance-none
									border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
									aria-label='Default select example'
									placeholder='Select Product'
								>
									<option value='CAPTURE' selected>
										Capture
									</option>
									<option value='VERISCRIPT'>
										Transcribe
									</option>
								</select>
							</div>
						</div>

						{(errors as any)?.productSku?.message && (
							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
									<span className='bg-white  text-red-500'>
										{(errors as any)?.productSku?.message}
									</span>
								</div>
							</div>
						)}

						<div className='flex flex-col mb-8'>
							<label className='inline-flex items-center cursor-pointer'>
								<input
									type='checkbox'
									className='form-checkbox border rounded text-blueGray-700 w-5 h-8 ease-linear transition-all duration-150 mr-3'
									{...register('allowOfflineAccess')}
								/>{' '}
								Allow Offline Access
							</label>
							<label className='block text-gray-600  mb-2 italic'>
								Help: Generate Refresh Token so that user
								doens't have to login as often
							</label>
						</div>

						<div className='flex flex-col mb-8'>
							<label className='inline-flex items-center cursor-pointer'>
								<input
									type='checkbox'
									className='form-checkbox border rounded text-blueGray-700 w-5 h-8 ease-linear transition-all duration-150 mr-3'
									{...register('requireClientSecret')}
								/>{' '}
								Require Client Secret?
							</label>
							<label className='block text-gray-600  mb-2 italic'>
								Help: For SPA app (React, Angular) untick this
								option
							</label>
						</div>
					</>
				) : null}

				{/* Allowed email domains */}
				{!isClientCredential ? (
					<>
						<div className='block uppercase text-gray-600  font-bold  pt-8'>Access Management</div>
						<ListStringUpdate
							title='Allowed Domains (All)'
							helpText='Help: All email addresses with sepcified email domain can access this application'
							fieldName='allowedEmailDomains'
							fields={allowedEmailDomains}
							append={appendallowedEmailDomains}
							remove={removeallowedEmailDomains}
							register={register}
							errors={errors}
							generateUuid={false}
							watch={watch}
						></ListStringUpdate>

						<ListStringUpdate
							title='Allowed Emails/Usernames (Individual)'
							helpText='Help: Configure who can access this application'
							fieldName='allowedEmailAddresses'
							fields={allowedEmailAddresses}
							append={appendallowedEmailAddresses}
							remove={removeallowedEmailAddresses}
							register={register}
							errors={errors}
							generateUuid={false}
							watch={watch}
						></ListStringUpdate>
					</>
				) : null}

				{/* redirect uri */}
				{!isClientCredential ? (
					<ListStringUpdate
						title='Redirect Uri'
						helpText='Help: Configure OIDC Redirect URL'
						fieldName='redirectUris'
						fields={redirectUris}
						append={appendredirectUris}
						remove={removeredirectUris}
						register={register}
						errors={errors}
						generateUuid={false}
						watch={watch}
					></ListStringUpdate>
				) : null}

				{/* redirect uri */}
				{!isClientCredential ? (
					<ListStringUpdate
						title='Post Logout Redirect'
						helpText='Help: Configure if user should be redirected back to the app after logging out'
						fieldName='postLogoutRedirectUris'
						fields={postLogoutRedirectUris}
						append={appendpostLogoutRedirectUris}
						remove={removepostLogoutRedirectUris}
						register={register}
						errors={errors}
						generateUuid={false}
						watch={watch}
					></ListStringUpdate>
				) : null}
			</div>

			{/* API Scope */}
			{isClientCredential ? (
				<ListStringUpdate
					title='API Scope'
					helpText='Help: Configure which OIDC Scope this app can access'
					fieldName='allowedScopes'
					fields={allowedScopes}
					append={appendallowedScopes}
					remove={removeallowedScopes}
					register={register}
					errors={errors}
					generateUuid={false}
					watch={watch}
				></ListStringUpdate>
			) : null}

			{/* client secret */}
			{requireClientSecret || isClientCredential ? (
				<ListStringUpdate
					title='Client Secret'
					fieldName='clientSecrets'
					helpText='Help: Configure OAuth 2.0 Client Secret'
					fields={clientSecrets}
					append={appendClientSecret}
					remove={removeClientSecret}
					register={register}
					errors={errors}
					generateUuid={true}
					watch={watch}
				></ListStringUpdate>
			) : null}

			<div className='relative bg-gray-300'>
				<div className='absolute left-0 top-0 flex justify-start w-full -mt-1'>
					<span className='bg-white  text-red-500'>{error}</span>
				</div>
			</div>
		</>
	);
}

export default ClientForm;
