import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from '../components/spinner';
import Header from '../components/header';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

function ForgotPassword() {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [token, setToken] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm({ mode: 'onChange' });

	const query = new URLSearchParams(window.location.search);
	const stripeSessionId = query.get('stripe_session_id');
	const product = query.get('product');

	const onLoginSubmit = (data: any) => {
		if (isValid) {
			if (!token) {
				setRefreshReCaptcha(r => !r);
				return;
      }
			setLoading(true);
			axios
				.post(`/api/auth/ForgotPassword`, {
					...data,
					stripeSessionId,
					product,
					token
				})
				.then((response: any) => {
					setLoading(false);
					setError('');
					setDone(true);
				})
				.catch((error) => {
					setLoading(false);
					setDone(false);
					if (error.response.status === 401) {
						setError('Please solve captcha before submit');
					} else {
						setError('Error occured. Unable to register user');
                    }
					setRefreshReCaptcha(r => !r);
				});
		}
	};

	const onVerify = useCallback((token) => {
		setToken(token);
	}, []);

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='font-bold text-3xl py-8'>Forgot Password</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-16 mb-16 rounded-md w-full max-w-md'>
				{!done ? (
					<div className='mt-2'>
						<div className='pb-6 text-gray-500 text-center  text-sm'>
							Enter your email address and we will send you
							password reset link
						</div>
						<form onSubmit={handleSubmit(onLoginSubmit)}>
							<div className='flex flex-col mb-4'>
								<div className='relative'>
									<input
										placeholder='Enter your email address'
										type='email'
										{...register('email', {
											required: true,
											pattern: {
												value: /\S+@\S+\.\S+/,
												message:
													'Entered value does not match email format',
											},
										})}
										className='text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'
									/>
								</div>
							</div>

							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-start w-full -mt-1'>
									<span className='bg-white text-xs text-red-500'>
										{error}
									</span>
								</div>
							</div>

							<GoogleReCaptcha
								action="forgotPassword"
								onVerify={onVerify}
								refreshReCaptcha={refreshReCaptcha}
							/>

							<div className='flex w-full mt-10'>
								<button
									type='submit'
									disabled={!isValid}
									className={cn('btn-default', {
										'bg-altia-blue hover:bg-blue-700':
											isValid,
										'bg-gray-400': !isValid,
									})}
								>
									{loading ? (
										<Spinner size='sm' color='white' />
									) : null}
									<span className='mr-2 uppercase'>
										Submit
									</span>
								</button>
							</div>
						</form>
					</div>
				) : (
					<div className='mt-2'>
						<div className='pb-6 text-gray-500 text-center  text-sm'>
							Check your email for password reset link
						</div>
					</div>
				)}

				<div className='flex justify-center items-center pt-4'>
					<Link
						to={{
							pathname: '/',
							...(!!stripeSessionId &&
								!!product && {
									search: `?stripe_session_id=${stripeSessionId}&product=${product}`,
								}),
						}}
					>
						<a className='link-default'>
							<span className='ml-2'>Go back to Login Page</span>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
