import Spinner from '../../components/spinner';
import UpdateClientform from '../../components/updateclientform';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UserContext } from '../Home';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function ClientEdit() {
	const { id } = useParams();
	const [client, setClient] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		axios
			.get(`/api/admin/clients/${encodeURIComponent(id)}`)
			.then((response) => {
				setLoading(false);
				setClient(response.data);
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Error loading apps');
			});
	}, []);

	const user: any = useContext(UserContext);
	return (
		<>
			<div className='px-4 sm:px-6 md:px-8 py-4'>
				{user?.claims && !loading ? (
					<>
						<UpdateClientform data={client}></UpdateClientform>
					</>
				) : (
					<div className='flex items-center'>
						<Spinner size='lg' color='blue' /> Loading...
					</div>
				)}
			</div>
		</>
	);
}
