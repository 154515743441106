import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SaveButton from './saveButton';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../containers/Home';

function ResetPasswordForm() {
	const context: any = useContext(UserContext);
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);
	const [mfaConfigs, setMfaConfigs] = useState(null);
	const formSchema = Yup.object().shape({
		code: Yup.string()
			.required('Code is mandatory')
			.min(6, 'Code must be min 6 char long')
			.max(7, 'Code must be max 7 char long'),
	});

	useEffect(() => {
		axios
			.get(`/api/user/2fa/configs`)
			.then((response) => setMfaConfigs(response.data))
			.catch((error) => toast.error('Error occured. Please try again'));
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	const onSave = (data: any) => {
		setSaving(true);
		axios
			.put(`/api/user/2fa/enable`, {
				...data,
			})
			.then((response: any) => {
				setSaving(false);
				setError('');
				toast('2fa successully enabled');
				navigate('/home/security', { replace: false });
				if (context?.reloadProfile) context?.reloadProfile();
			})
			.catch((error) => {
				setSaving(false);
				if (error?.response?.data?.code) {
					setError(error?.response?.data?.code);
					return;
				}
				setError('Error occured. Please try again');
			});
	};

	return (
		<div className='px-4 py-4 max-w-xl'>
			<div className='uppercase text-xl font-semibold'>
				Two-step verification
			</div>

			<div className='py-8'>
				<p>
					To use an authenticator app go through the following steps:
				</p>
				<p className='py-2'>
					Download a two-factor authenticator app like Microsoft
					Authenticator for
					<a
						className='inline-flex text-blue-500 hover:text-blue-700 font-semibold mx-1'
						href='https://go.microsoft.com/fwlink/?Linkid=825072'
						target='_blank'
						rel='noreferrer'
					>
						Android
					</a>{' '}
					and
					<a
						href='https://go.microsoft.com/fwlink/?Linkid=825073'
						className='inline-flex text-blue-500 hover:text-blue-700 font-semibold mx-1'
					>
						iOS
					</a>{' '}
					or Google Authenticator for
					<a
						href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en'
						className='inline-flex text-blue-500 hover:text-blue-700 font-semibold mx-1'
						target='_blank'
						rel='noreferrer'
					>
						Android
					</a>{' '}
					and
					<a
						href='https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8'
						className='inline-flex text-blue-500 hover:text-blue-700 font-semibold mx-1'
						target='_blank'
						rel='noreferrer'
					>
						iOS
					</a>
					.
				</p>
				<p className='py-2'>
					Scan the QR Code or enter this key{' '}
					<kbd className='font-semibold'>{mfaConfigs?.sharedKey}</kbd>{' '}
					into your two factor authenticator app. Spaces and casing do
					not matter.
				</p>
				{mfaConfigs?.authenticatorUri ? (
					<div className='w-52 h-52 mx-auto my-6'>
						<QRCode
							size={256}
							style={{
								height: 'auto',
								maxWidth: '100%',
								width: '100%',
							}}
							value={mfaConfigs?.authenticatorUri}
							viewBox={`0 0 256 256`}
						/>
					</div>
				) : null}

				<p className='py-2'>
					Once you have scanned the QR code or input the key above,
					your two factor authentication app will provide you with a
					unique code. Enter the code in the confirmation box below.
				</p>
				<form
					onSubmit={handleSubmit(onSave)}
					className='max-w-sm pt-10'
				>
					<div className='flex flex-col mb-4'>
						<div
							className={
								'relative group ' +
								((errors as any)?.code ? 'isInvalid' : '')
							}
						>
							<input
								type='text'
								placeholder='Enter code'
								{...register('code')}
								className='form-field'
							/>
						</div>
					</div>

					{(errors as any)?.code?.message && (
						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-end w-full -mt-3'>
								<span className='bg-white text-xs text-altia-red'>
									{(errors as any)?.code?.message}
								</span>
							</div>
						</div>
					)}

					<div className='relative bg-gray-300 mt-5'>
						<div className='absolute left-0 top-0 flex justify-start w-full'>
							<span className='bg-white text-xs text-altia-red'>
								{error}
							</span>
						</div>
					</div>

					<div className='flex w-full mt-10 justify-start'>
						<SaveButton saving={saving} type='submit'>
							Verify
						</SaveButton>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ResetPasswordForm;
