import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import SaveButton from './saveButton';
import axios from 'axios';
import { UserContext } from '../containers/Home';
import { toast } from 'react-toastify';

export default function TwoFactor({ user }: any) {
	const [reseting2fa, setSaving] = useState(false);
	const [disable2fa, setDisable2fa] = useState(false);
	const [forgettingDevice, setForgetting] = useState(false);

	const context: any = useContext(UserContext);

	const forgetBrowser = () => {
		setForgetting(true);
		axios
			.put(`/api/user/2fa/forgetdevice`)
			.then(() => {
				setForgetting(false);
				toast(
					'The current browser has been forgotten. When you login again from this browser you will be prompted for your 2fa code.'
				);
				if (context?.reloadProfile) context?.reloadProfile();
			})
			.catch((error) => {
				setForgetting(false);
				toast.error('Error occured. Please try again later');
			});
	};

	const reset2fa = () => {
		setSaving(true);
		axios
			.put(`/api/user/2fa/reset`)
			.then(() => {
				setSaving(false);
				toast(
					'Your authenticator app key has been reset and 2fa has been disabled, you will need to configure your authenticator app using the new key.'
				);
				if (context?.reloadProfile) context?.reloadProfile();
			})
			.catch((error) => {
				setSaving(false);
				toast.error('Error occured. Please try again later');
			});
	};

	const disable2faClicked = () => {
		setDisable2fa(true);
		axios
			.put(`/api/user/2fa/disable`)
			.then(() => {
				setDisable2fa(false);
				toast(
					'2fa has been disabled. You can re-enable 2fa when you setup an authenticator app.'
				);

				if (context?.reloadProfile) context?.reloadProfile();
			})
			.catch((error) => {
				setDisable2fa(false);
				toast.error('Error occured. Please try again later');
			});
	};

	return (
		<div className=''>
			<div className='uppercase text-xl font-semibold'>
				Two-step verification
			</div>
			{user.is2faEnabled ? (
				<div>
					{user.recoveryCodesLeft === 0 ? (
						<div className=''>
							<div className='font-semibold'>
								You have no recovery codes left.
							</div>
							<p>
								You must{' '}
								<Link to='generateRecoveryCodes'>
									<a className='inline-flex text-blue-500 hover:text-blue-700 font-semibold'>
										generate a new set of recovery codes
									</a>
								</Link>{' '}
								before you can log in with a recovery code.
							</p>
						</div>
					) : null}
					{user.recoveryCodesLeft === 1 ? (
						<div className=''>
							<div className='font-semibold'>
								You have 1 recovery code left.
							</div>
							<p>
								You can{' '}
								<Link to='generateRecoveryCodes'>
									<a className='inline-flex text-blue-500 hover:text-blue-700 font-semibold'>
										generate a new set of recovery codes
									</a>
								</Link>
								.
							</p>
						</div>
					) : null}

					{user.recoveryCodesLeft <= 3 &&
					user.recoveryCodesLeft > 0 ? (
						<div className=''>
							<div className='font-semibold'>
								You have {user.recoveryCodesLeft} recovery code
								left.
							</div>
							<p>
								You should{' '}
								<Link to='generateRecoveryCodes'>
									<a className='inline-flex text-blue-500 hover:text-blue-700 font-semibold'>
										generate a new set of recovery codes
									</a>
								</Link>
								.
							</p>
						</div>
					) : null}

					{user.isMachineRemembered ? (
						<div className='py-6'>
							<div className='py-2  text-gray-500'>
								You are not asked to login using MFA on this
								machine.
							</div>
							<SaveButton
								onClick={forgetBrowser}
								saving={forgettingDevice}
								type='button'
							>
								Forget this machine
							</SaveButton>
						</div>
					) : null}

					<div className='text-gray-500 py-6'>
						<SaveButton
							onClick={disable2faClicked}
							saving={disable2fa}
							type='button'
						>
							Disable 2FA
						</SaveButton>
					</div>
				</div>
			) : null}

			{!user.hasAuthenticator ? (
				<div className='py-6'>
					<div className=' text-gray-500'>
						Keep your account extra secure with a second login step.
					</div>
					<div className='py-2'>
						<Link to='setup2fa'>
							<a className='inline-flex link-default'>
								Add authenticator app
							</a>
						</Link>
					</div>
				</div>
			) : (
				<div className='py-6'>
					<div className=' text-gray-500'>
						Keep your account extra secure with a second login step.
					</div>
					<p className='py-2'>
						<Link to='setup2fa'>
							<a className='inline-flex text-altia-blue hover:text-blue-700 font-semibold'>
								Setup authenticator app
							</a>
						</Link>
					</p>
					<p className='py-2'>
						<SaveButton
							onClick={reset2fa}
							saving={reseting2fa}
							type='button'
						>
							Reset authenticator app
						</SaveButton>
					</p>
				</div>
			)}
		</div>
	);
}
