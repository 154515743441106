import Spinner from '../../components/spinner';
import { UserContext } from '../../containers/Home';
import { useContext } from 'react';
import PasswordResetForm from '../../components/passwordresetform';
import TwoFactor from '../../components/twofactor';

export default function Home() {
	const user: any = useContext(UserContext);
	return (
		<>
			<div className='w-full px-4 sm:px-6 md:px-8 py-4'>
				{user?.claims ? (
					<div className='px-4 py-4 max-w-md'>
						<div className='text-left '>
							<span className='uppercase text-xl font-semibold'>
								Security
							</span>
							{user.is2faEnabled ? (
								<span className='rounded-lg px-2 text-sm mx-2 bg-green-700 text-white border-2'>
									2FA
								</span>
							) : null}
						</div>

						{/* reset password */}
						{user.hasPassword ? (
							<div className='py-8'>
								<PasswordResetForm
									data={{
										email: user?.claims?.claims?.email,
										firstName:
											user?.claims?.claims?.given_name,
										lastName:
											user?.claims?.claims?.family_name,
									}}
								></PasswordResetForm>
							</div>
						) : null}

						{/* 2fa stuffs */}
						{!user.isExternal ? (
							<div className='py-8'>
								<TwoFactor user={user}></TwoFactor>
							</div>
						) : (
							<div>
								<div className='text-left'>
									You logged in as{' '}
									<span className='text-blue-600'>
										{user?.claims?.claims?.email}
									</span>{' '}
									via{' '}
									{user.idp || 'external identity provider'}
									.
									<br />
									<br />
									Please login to {user.idp ||
										'that system'}{' '}
									to manage your security settings
								</div>
							</div>
						)}
					</div>
				) : (
					<div className=''>
						<Spinner size='lg' color='blue' /> Loading your
						profile...
					</div>
				)}
			</div>
		</>
	);
}
