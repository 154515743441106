import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SaveButton from './saveButton';
import ClientForm from './clientform';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

function UpdateClientform({ data }) {
	const isNew = !data?.clientId;
	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);
	const navigate = useNavigate();

	const formSchema = Yup.object().shape(
		{
			clientId: Yup.string()
				.required('Client Id is mandatory')
				.min(6, 'ClientId must be at 6 char long')
				.matches(
					/^[.a-zA-Z_-\d]{6,100}$/g,
					'Client Id can only contains alphanumeric, underscore, dash and dot'
				),
			clientName: Yup.string()
				.required('Client Name is mandatory')
				.min(6, 'Client Name must be at least 6 char long'),
			launchUrl: Yup.string().when('launchUrl', (val, schema) => {
				if (val?.length > 0) {
					return Yup.string().matches(
						/https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/gi,
						'Enter valid URL'
					);
				} else {
					return Yup.string().notRequired();
				}
			}),
			clientSecrets: Yup.array().when(
				['isClientCredential', 'requireClientSecret'],
				{
					is: (isClientCredential, requireClientSecret) => {
						return isClientCredential || requireClientSecret;
					},
					then: Yup.array()
						.of(
							Yup.object().shape({
								value: Yup.string()
									.required('Secret value is requried')
									.min(8, 'Must be at least 8 char long'),
							})
						)
						.min(1, 'At least one App Secret is required'),
				}
			),
			redirectUris: Yup.array().when(['isClientCredential'], {
				is: (isClientCredential) => {
					return !isClientCredential;
				},
				then: Yup.array()
					.of(
						Yup.object().shape({
							value: Yup.string()
								.required('Redirect Uri is requried')
								.matches(
									/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/gi,
									'Enter valid URL'
								),
						})
					)
					.min(1, 'At least one Redirect is required'),
			}),
			allowedScopes: Yup.array().when(['isClientCredential'], {
				is: (isClientCredential) => {
					return isClientCredential;
				},
				then: Yup.array()
					.of(
						Yup.object().shape({
							value: Yup.string()
								.required('scope is required')
								.matches(
									/^[.a-zA-Z_-\d]{6,100}$/g,
									'Scope can only contains alphanumeric, underscore, dash and dot'
								),
						})
					)
					.min(1, 'At least one API Scope is required'),
			}),
			allowedEmailDomains: Yup.array().when(['allowedEmailDomains'], {
				is: (allowedEmailDomains) => {
					return allowedEmailDomains?.length > 0;
				},
				then: Yup.array().of(
					Yup.object().shape({
						value: Yup.string().matches(
							/\w+(?:\.\w\w)?\.\w+$/gi,
							'Enter domain only, without @'
						),
					})
				),
			}),
			allowedEmailAddresses: Yup.array().when(['allowedEmailAddresses'], {
				is: (allowedEmailAddresses) => {
					return allowedEmailAddresses?.length > 0;
				},
				then: Yup.array().of(
					Yup.object().shape({
						value: Yup.string().matches(
							/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							'Enter valid email address'
						),
					})
				),
			}),
			postLogoutRedirectUris: Yup.array().when(
				['postLogoutRedirectUris'],
				{
					is: (postLogoutRedirectUris) => {
						return postLogoutRedirectUris?.length > 0;
					},
					then: Yup.array().of(
						Yup.object().shape({
							value: Yup.string().matches(
								/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/gi,
								'Enter valid URL'
							),
						})
					),
				}
			),
		},
		[
			['redirectUris', 'isClientCredential'], // cyclic dependencies
			['allowedScopes', 'isClientCredential'],
			['clientSecrets', 'isClientCredential'],
			['allowedEmailDomains', 'allowedEmailDomains'],
			['allowedEmailAddresses', 'allowedEmailAddresses'],
			['postLogoutRedirectUris', 'postLogoutRedirectUris'],
			['launchUrl', 'launchUrl'],
		]
	);

	const {
		register,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors, isValid },
	} = useForm({
		defaultValues: {
			...data,
			clientId: isNew ? uuidv4() : data?.clientId,
			icon: data?.properties?.find((x) => x.key === 'icon')?.value,
			launchUrl: data?.properties?.find((x) => x.key === 'launchUrl')
				?.value,
			isClientCredential:
				data?.allowedGrantTypes?.filter(
					(a) => a.grantType === 'client_credentials'
				).length > 0,
			productSku: data?.properties?.find((x) => x.key === 'sku')?.value,
			redirectUris: data?.redirectUris?.map((x) => ({
				value: x.redirectUri,
			})),
			allowedScopes: data?.allowedScopes?.map((x) => ({
				value: x.scope,
			})),
			allowedEmailDomains: data?.claims
				?.filter((x) => x.type === 'user_domain')
				.map((x) => ({
					value: x.value,
				})),
			allowedEmailAddresses: data?.claims
				?.filter((x) => x.type === 'user_email')
				.map((x) => ({
					value: x.value,
				})),
			postLogoutRedirectUris: data?.postLogoutRedirectUris?.map((x) => ({
				value: x.postLogoutRedirectUri,
			})),
		},
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	const deleteClient = () => {
		if (window.confirm('Are you sure you want to delete?')) {
			if (window.confirm('This action is irreversible. Are you 100% sure??')) {
				axios
					.request({
						method: 'DELETE',
						url: `/api/admin/clients/${encodeURIComponent(
							data?.clientId
						)}`,
					})
					.then((response: any) => {
						toast(`Application deleted`);
						navigate('/home/admin', { replace: false });
					})
					.catch((error) => {
						toast.error('Error occured. Please try again later');
					});
			}
		}
	};

	const onSave = (newData: any) => {
		// console.log("🚀 ~ file: updateclientform.tsx:163 ~ onSave ~ newData", newData)
		// return;
		const transformed = {
			...newData,
			redirectUris: newData.redirectUris.map((x) => x.value),
			allowedScopes: newData.allowedScopes.map((x) => x.value),
			allowedEmailDomains: newData.allowedEmailDomains.map(
				(x) => x.value
			),
			allowedEmailAddresses: newData.allowedEmailAddresses.map(
				(x) => x.value
			),
			postLogoutRedirectUris: newData.postLogoutRedirectUris.map(
				(x) => x.value
			),
			productIcon:
				newData.productSku === 'CAPTURE'
					? '/images/capture.png'
					: newData.productSku === 'VERISCRIPT'
					? '/images/veriscript.png'
					: '',
		};

		const url = isNew
			? `/api/admin/clients`
			: `/api/admin/clients/${encodeURIComponent(data?.clientId)}`;

		setSaving(true);
		axios
			.request({
				method: isNew ? 'POST' : 'PUT',
				url: url,
				data: transformed,
			})
			.then((response: any) => {
				setSaving(false);
				setError('');
				toast(
					`Application ${isNew ? 'Created' : 'Updated'} Successfully`
				);
			})
			.catch((error) => {
				setSaving(false);
				toast.error('Error occured. Please try again later');
			});
	};

	return (
		<div className='px-4 py-4 pt-10 max-w-3xl'>
			<div className='uppercase text-xl font-semibold'>
				{isNew ? 'Add ' : 'Update '}Connected App Configuration
			</div>
			<form onSubmit={handleSubmit(onSave)} className='py-8'>
				<ClientForm
					errors={errors}
					error={error}
					register={register}
					watch={watch}
					control={control}
				/>

				<div className='flex w-full mt-10 space-x-2'>
					<SaveButton saving={saving} type='submit'>
						Save Changes
					</SaveButton>

					{!isNew ? (
						<button
							type='button'
							className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 px-4 bg-red-600 hover:bg-red-700'
							onClick={deleteClient}
						>
							<TrashIcon className='flex-shrink-0 h-6 w-6 mr-2' />{' '}
							Delete
						</button>
					) : null}
					<button
						type='button'
						className='flex items-center text-white justify-center focus:outline-none rounded-md py-2 px-4 bg-gray-600 hover:bg-gray-700'
						onClick={() => reset()}
					>
						Reset
					</button>
				</div>
			</form>
		</div>
	);
}

export default UpdateClientform;
