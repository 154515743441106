import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SaveButton from '../components/saveButton';
import UserForm from '../components/userform';
import { toast } from 'react-toastify';
import { UserContext } from '../containers/Home';
import { yupResolver } from '@hookform/resolvers/yup';
import OtpDialog from '../components/otpdialog';
import * as Yup from 'yup';

function UpdateProfileForm({ data }) {
	const context: any = useContext(UserContext);
	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);

	const formSchema = Yup.object().shape({
		firstName: Yup.string()
			.required('First name is mandatory')
			.min(2, 'Password must be at 2 char long'),
		lastName: Yup.string()
			.required('Last name is mandatory')
			.min(2, 'Password must be at 2 char long'),
		company: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Company must be at 2 char long'),
		position: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Position must be at 2 char long'),
		workUnit: Yup.string()
			.required('Work Unit is mandatory')
			.min(2, 'Work Unit must be at 2 char long'),
		employeeId: Yup.string()
			.trim()
			.nullable()
			.min(2, 'Employee ID must be at 2 char long'),
		mobileNumber: Yup.string()
			.required('Mobile number is mandatory')
			.matches(
			/\+\d{10}/,
			'Enter mobile with country code (e.g. +614..)'
		)
	});

	const {
		register,
		handleSubmit,
		getValues,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		defaultValues: data,
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	const onSave = (data: any) => {
		setSaving(true);
		axios
			.put(`/api/user/profile`, {
				...data,
			})
			.then((response: any) => {
				setSaving(false);
				setError('');
				if (response.data.requireOtp) {
					setOtpIsOpen(true);
				} else {
					setOtpIsOpen(false);
					toast('User profile updated');
					if (context?.reloadProfile) context?.reloadProfile();
				}
			})
			.catch((error) => {
				setSaving(false);
				if (error?.response?.data?.otpCode) {
					setError(error?.response?.data?.otpCode[0]);
					setOtpIsOpen(true);
					return;
				}

				toast.error('Error occured. Unable to update details');
			});
	};

	const [isOtpOpen, setOtpIsOpen] = useState(false);
	const onEnterOtp = (otp: string) => {
		const formD = getValues();
		onSave({
			...formD,
			otpCode: otp,
		});
	};

	const resendOtp = () => {
		const formD = getValues();
		setOtpIsOpen(false);
		axios
			.post(`/api/auth/ResendOtp`, formD)
			.then((response: any) => {
				setOtpIsOpen(true);
			})
			.catch((error) => {
				setOtpIsOpen(true);
				setError('Error occured. Unable to send OTP');
			});
	};

	return (
		<div className='px-4 py-4 max-w-md'>
			<OtpDialog
				isOpen={isOtpOpen}
				isLoading={saving}
				error={error}
				closeModal={() => setOtpIsOpen(false)}
				onEnterOtp={onEnterOtp}
				resendOtp={resendOtp}
			></OtpDialog>

			<div className='uppercase text-xl font-semibold'>
				Update Your Details
			</div>
			<form onSubmit={handleSubmit(onSave)} className='py-8'>
				<UserForm
					control={control}
					errors={errors}
					showPassword={false}
					emailReadonly={true}
					showMobileVerifedStatus
					mobileIsVerified={data?.mobileNumberVerfied === 'True'}
					error={error}
					register={register}
					showTimezone={true}
					setValue={setValue}
				/>

				<div className='flex w-full mt-10'>
					<SaveButton
						saving={saving}
						type='submit'
						disabled={!isValid}
					>
						Save Changes
					</SaveButton>
				</div>
			</form>

			{!(data?.mobileNumberVerfied === 'True') ? (
				<div>
					Mobile number is not verified.
					<button
						onClick={resendOtp}
						type='button'
						className='link-default pl-2'
					>
						Verify it now
					</button>
				</div>
			) : null}
		</div>
	);
}

export default UpdateProfileForm;