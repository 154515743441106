import Enable2fa from '../../components/enable2fa';
import Spinner from '../../components/spinner';
import { UserContext } from '../Home';
import { useContext } from 'react';

export default function Home() {
	const user: any = useContext(UserContext);
	return (
		<>
			<div className='px-4 sm:px-6 md:px-8'>
				{/* Replace with your content */}
				<div className='py-4'>
					<div className=''>
						{user?.claims ? (
							<Enable2fa></Enable2fa>
						) : (
							<div className='flex justify-center items-center pt-20'>
								<Spinner size='lg' color='blue' /> Loading your
								profile...
							</div>
						)}
					</div>
				</div>
				{/* /End replace */}
			</div>
		</>
	);
}
