import { Controller } from 'react-hook-form';
import PhoneNumber from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

const PhoneNumberInput: React.FC<{
	name: string;
	control: any;
	onCountryChange: (country) => void;
}> = ({ name, control, onCountryChange }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange, onBlur } }) => {
				return (
					<PhoneNumber
						flags={flags}
						placeholder='Mobile number with country code'
						international
						className='form-field'
						value={value}
						onChange={(e) => onChange(e || '')}
						onCountryChange={onCountryChange}
					/>
				);
			}}
		/>
	);
};
export default PhoneNumberInput;