import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from '../components/spinner';
import Header from '../components/header';

function ResendVerification() {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm({ mode: 'onChange' });

	const onLoginSubmit = (data: any) => {
		if (isValid) {
			setLoading(true);
			axios
				.post(`/api/auth/ResendVerificationEmail`, {
					...data,
				})
				.then((response: any) => {
					setLoading(false);
					setError('');
					setDone(true);
				})
				.catch((error) => {
					setLoading(false);
					setDone(false);
					setError('Error occured. Unable to register user');
				});
		}
	};

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='font-bold text-3xl py-8'>
				Email Verification Required
			</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-16 mb-16 rounded-md w-full max-w-md'>
				{!done ? (
					<div className='mt-2'>
						<div className='pb-6 text-gray-500 text-center  text-sm'>
							You haven't verified your email address. Please
							check your inbox (including spam folder) for a
							verification email. <br /><br />
							If you haven't received this email, re-enter your
							email again (below) and we will attempt to send you
							another link.
						</div>
						<form onSubmit={handleSubmit(onLoginSubmit)}>
							<div className='flex flex-col mb-4'>
								<div className='relative'>
									<input
										placeholder='Enter your email address'
										type='email'
										{...register('email', {
											required: true,
											pattern: {
												value: /\S+@\S+\.\S+/,
												message:
													'Entered value does not match email format',
											},
										})}
										className='text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'
									/>
								</div>
							</div>

							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-start w-full -mt-1'>
									<span className='bg-white text-xs text-red-500'>
										{error}
									</span>
								</div>
							</div>

							<div className='flex w-full mt-10'>
								<button
									type='submit'
									disabled={!isValid}
									className={cn('btn-default', {
										'bg-altia-blue hover:bg-blue-700':
											isValid,
										'bg-gray-400': !isValid,
									})}
								>
									{loading ? (
										<Spinner size='sm' color='white' />
									) : null}
									<span className='mr-2 uppercase'>
										Submit
									</span>
								</button>
							</div>
						</form>
					</div>
				) : (
					<div className='mt-2'>
						<div className='pb-6 text-gray-500 text-center  text-sm'>
							Check your email for verification link
						</div>
					</div>
				)}

				<div className='flex justify-center items-center pt-4'>
					<Link to='/'>
						<a className='link-default'>
							<span className='ml-2'>Go back to Login Page</span>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ResendVerification;
