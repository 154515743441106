import React, {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import Spinner from "./spinner";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export default function ModalDigitConfirm(props) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [saving, setSaving] = useState(false);
  const inputRefs = useRef([]);
  const {isOpen, closeModal, closeParentModal, data} = props;
  const navigate = useNavigate();
  const handleKeyDown = (e) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = inputRefs.current.indexOf(e.target);
      if (index > 0) {
        setOtp((prevOtp) => [
          ...prevOtp.slice(0, index - 1),
          "",
          ...prevOtp.slice(index),
        ]);
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target);
    if (target.value) {
      setOtp((prevOtp) => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setOtp(digits);
  };

  const handleVerifyOtp = () => {
    setSaving(true);
    axios
      .put(`/api/user/UpdateEmailAddress`, {email: data, code: otp.join("")})
      .then((response) => {
        toast('Email updated successfully');
        closeModal();
        closeParentModal();
        navigate(0);
        setSaving(false);
      })
      .catch((error) => {
        toast.error('Invalid OTP code');
        setSaving(false);
      });
  }

  const handleCancelModal = () => {
    closeModal();
    setOtp(Array(6).fill(""));
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Enter OTP
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please enter the 6 digits number that was sent to your new email to confirm
                    </p>
                  </div>
                  <section className="w-full py-5">
                    <div className="container">
                      <form id="otp-form" className="flex gap-2 justify-between">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={handleInput}
                            onKeyDown={handleKeyDown}
                            onFocus={handleFocus}
                            onPaste={handlePaste}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="shadow-xs flex w-[50px] items-center justify-center rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
                          />
                        ))}
                      </form>
                    </div>
                  </section>
                  <div className="mt-5 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex mr-4 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleCancelModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="inline-flex disabled:bg-gray-300 uppercase bg-blue-500 text-white content-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      disabled={saving}
                      onClick={handleVerifyOtp}
                    >
                      <span>
                        {saving && <Spinner size="sm" color="white"/>}
                      </span>
                      OK
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
