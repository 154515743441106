import Spinner from '../../components/spinner';
import UpdateClientform from '../../components/updateclientform';
import { UserContext } from '../Home';
import { useContext, useState } from 'react';

export default function AddClient() {
	const [client, setClient] = useState(null);
	const [loading, setLoading] = useState(false);

	const user: any = useContext(UserContext);
	return (
		<>
			<div className='px-4 sm:px-6 md:px-8 py-4'>
				{user?.claims && !loading ? (
					<>
						<UpdateClientform data={{}}></UpdateClientform>
					</>
				) : (
					<div className='flex items-centefr'>
						<Spinner size='lg' color='blue' /> Loading...
					</div>
				)}
			</div>
		</>
	);
}
