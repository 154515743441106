import { Fragment, useEffect, createContext, useState } from 'react';
import axios from 'axios';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
	Bars3Icon,
	HomeIcon,
	ShieldCheckIcon,
	XMarkIcon,
	SquaresPlusIcon,
	Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import {
	useNavigate,
	NavLink as Link,
	Outlet,
	useLocation,
} from 'react-router-dom';

const navigation = [
	{ name: 'Profile', href: 'index', icon: HomeIcon, current: true },
	{
		name: 'Security',
		href: 'security',
		icon: ShieldCheckIcon,
		requireAdmin: false,
		current: false,
	},
	{
		name: 'Connected Apps',
		href: 'apps',
		icon: SquaresPlusIcon,
		requireAdmin: false,
		current: false,
	},
	{
		name: 'Altia Admin Portal',
		href: 'admin',
		icon: Cog6ToothIcon,
		requireAdmin: true,
		current: false,
	},
];

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

export const UserContext = createContext({});

export default function Home() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [profile, setProfile] = useState<any>(null);
	const [error, setError] = useState<any>(null);

	const onReloadProfile = () => {
		axios
			.get(`/api/user/profile`)
			.then((response) => setProfile(response.data))
			.catch((error) => {
				if (error.response.status === 401) {
					navigate('/', { replace: false });
				}
				setError('Error loading claims');
			});
	};

	useEffect(() => {
		onReloadProfile();
	}, []);

	const navigate = useNavigate();

	const onLogout = () => {
		axios
			.post(`/api/user/logout`)
			.then((response) => {
				navigate('/', { replace: false });
			})
			.catch((error) => {
				navigate('/', { replace: false });
			});
	};

	// GTM data layer
	useEffect(() => {
		if (profile && (window as any).dataLayer) {
			(window as any).dataLayer.push({
				email: profile?.claims?.claims?.email,
				phone: profile?.claims?.claims?.mobileNumber,
				company: profile?.claims?.claims?.company,
				user_id: profile?.claims?.claims?.sub,
				name:
					profile?.claims?.claims?.given_name +
					' ' +
					profile?.claims?.claims?.family_name,
			});

			(window as any).dataLayer.push({
				event: 'user_loaded',
			});

			if ((window as any).Intercom) {
				(window as any).Intercom('update', {
					...(window as any).intercomSettings,
					email: profile?.claims?.claims?.email,
					phone: profile?.claims?.claims?.mobileNumber,
					name:
						profile?.claims?.claims?.given_name +
						' ' +
						profile?.claims?.claims?.family_name,
					user_id: profile?.claims?.claims?.sub,
				});
			}
		}
	}, [profile]);

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as='div'
						className='relative z-40 md:hidden'
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter='transition-opacity ease-linear duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='transition-opacity ease-linear duration-300'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
						</Transition.Child>

						<div className='fixed inset-0 z-40 flex'>
							<Transition.Child
								as={Fragment}
								enter='transition ease-in-out duration-300 transform'
								enterFrom='-translate-x-full'
								enterTo='translate-x-0'
								leave='transition ease-in-out duration-300 transform'
								leaveFrom='translate-x-0'
								leaveTo='-translate-x-full'
							>
								<Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
									<Transition.Child
										as={Fragment}
										enter='ease-in-out duration-300'
										enterFrom='opacity-0'
										enterTo='opacity-100'
										leave='ease-in-out duration-300'
										leaveFrom='opacity-100'
										leaveTo='opacity-0'
									>
										<div className='absolute top-0 right-0 -mr-12 pt-2'>
											<button
												type='button'
												className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
												onClick={() =>
													setSidebarOpen(false)
												}
											>
												<span className='sr-only'>
													Close sidebar
												</span>
												<XMarkIcon
													className='h-6 w-6 text-white'
													aria-hidden='true'
												/>
											</button>
										</div>
									</Transition.Child>
									<div className='h-0 flex-1 overflow-y-auto pt-5 pb-4'>
										<div className='flex flex-shrink-0 items-center px-4'>
											<img
												className='h-14 w-auto'
												src='/AltiaIDlogo.png'
												alt='Altia Identity'
											/>
										</div>
										<nav className='mt-5 space-y-1 px-2'>
											{navigation
												.filter(
													(x) =>
														!x.requireAdmin ||
														(x.requireAdmin &&
															profile?.claims
																?.claims
																?.SuperAdmin ===
																'SuperAdmin')
												)
												.map((item) => (
													<Link
														key={item.name}
														to={item.href}
														className={({
															isActive,
														}) =>
															isActive
																? 'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-gray-200 text-gray-900 w-full'
																: 'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
														}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-gray-500'
																	: 'text-gray-400 group-hover:text-gray-500',
																'mr-3 flex-shrink-0 h-6 w-6'
															)}
															aria-hidden='true'
														/>
														{item.name}
													</Link>
												))}
										</nav>
									</div>
									<div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
										<Menu
											as='div'
											className='ml-3 relative'
										>
											<div className='flex flex-row justify-center items-center space-x-2'>
												<Menu.Button className='flex justify-center items-center space-x-2'>
													<div className='max-w-xs flex justify-center bg-altia-red text-white items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 w-8'>
														<span className='sr-only'>
															Open user menu
														</span>
														{profile?.claims?.name
															.split(' ')
															.map((n) => n[0])
															.join('')}
													</div>
													<div>
														{profile?.claims?.name}
													</div>
												</Menu.Button>
											</div>
											<Transition
												as={Fragment}
												enter='transition ease-out duration-100'
												enterFrom='transform opacity-0 scale-95'
												enterTo='transform opacity-100 scale-100'
												leave='transition ease-in duration-75'
												leaveFrom='transform opacity-100 scale-100'
												leaveTo='transform opacity-0 scale-95'
											>
												<Menu.Items className='origin-bottom-left absolute left-11 bottom-9 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none'>
													<Menu.Item>
														{({ active }) => (
															<button
																onClick={
																	onLogout
																}
																className={classNames(
																	active
																		? 'bg-gray-100'
																		: '',
																	'block px-4 py-2 text-sm text-gray-700 w-full'
																)}
															>
																Sign out
															</button>
														)}
													</Menu.Item>
												</Menu.Items>
											</Transition>
										</Menu>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div className='w-14 flex-shrink-0'>
								{/* Force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white'>
						<div className='flex flex-1 flex-col overflow-y-auto pt-5 pb-4'>
							<div className='flex flex-shrink-0 items-center px-4'>
								<img
									className='h-14 w-auto'
									src='/AltiaIDlogo.png'
									alt='Altia Identity'
								/>
							</div>
							<nav className='mt-5 flex-1 space-y-1 bg-white px-2'>
								{navigation
									.filter(
										(x) =>
											!x.requireAdmin ||
											(x.requireAdmin &&
												profile?.claims?.claims
													?.SuperAdmin ===
													'SuperAdmin')
									)
									.map((item) => (
										<Link
											key={item.name}
											to={item.href}
											className={({ isActive }) =>
												isActive
													? 'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-gray-200 text-gray-900 w-full'
													: 'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
											}
										>
											<item.icon
												className={classNames(
													item.current
														? 'text-gray-500'
														: 'text-gray-400 group-hover:text-gray-500',
													'mr-3 flex-shrink-0 h-6 w-6'
												)}
												aria-hidden='true'
											/>
											{item.name}
										</Link>
									))}
							</nav>
						</div>
						<div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
							<span className='group block w-full flex-shrink-0'>
								<div className='flex items-center'>
									<div>
										<p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
											<Menu
												as='div'
												className='ml-3 relative'
											>
												<div className='flex flex-row justify-center items-center space-x-2'>
													<Menu.Button className='flex justify-center items-center space-x-2'>
														<div className='max-w-xs flex justify-center bg-altia-red text-white items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 w-8'>
															<span className='sr-only'>
																Open user menu
															</span>
															{profile?.claims?.name
																.split(' ')
																.map(
																	(n) => n[0]
																)
																.join('')}
														</div>
														<div>
															{
																profile?.claims
																	?.name
															}
														</div>
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter='transition ease-out duration-100'
													enterFrom='transform opacity-0 scale-95'
													enterTo='transform opacity-100 scale-100'
													leave='transition ease-in duration-75'
													leaveFrom='transform opacity-100 scale-100'
													leaveTo='transform opacity-0 scale-95'
												>
													<Menu.Items className='origin-bottom-left absolute left-11 bottom-9 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none'>
														<Menu.Item>
															{({ active }) => (
																<button
																	onClick={
																		onLogout
																	}
																	className={classNames(
																		active
																			? 'bg-gray-100'
																			: '',
																		'block px-4 py-2 text-sm text-gray-700 w-full'
																	)}
																>
																	Sign out
																</button>
															)}
														</Menu.Item>
													</Menu.Items>
												</Transition>
											</Menu>
										</p>
									</div>
								</div>
							</span>
						</div>
					</div>
				</div>

				<div className='flex flex-1 flex-col md:pl-64'>
					<div className='sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden'>
						<button
							type='button'
							className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
							onClick={() => setSidebarOpen(true)}
						>
							<span className='sr-only'>Open sidebar</span>
							<Bars3Icon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<main className='flex-1'>
						<div className='py-6'>
							<UserContext.Provider
								value={{
									...profile,
									reloadProfile: onReloadProfile,
								}}
							>
								<Outlet />
							</UserContext.Provider>
						</div>
					</main>
				</div>
			</div>
		</>
	);
}
