import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from '../components/spinner';
import Header from '../components/header';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';

function Login2fa() {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm({ mode: 'onChange' });

	const query = new URLSearchParams(window.location.search);
	const returnUrl = query.get('returnUrl') || '/home';
	const rememberMe = query.get('rememberMe') === 'true';
	const navigate = useNavigate();

	const onLoginSubmit = (data: any) => {
		if (isValid) {
			setLoading(true);
			axios
				.post(`/api/auth/login2fa`, {
					...data,
					returnUrl,
					rememberMe,
				})
				.then((response: any) => {
					setLoading(false);
					setError('');

					if (returnUrl === '/home') {
						// direct login to the ID
						navigate(returnUrl, { replace: false });
					} else {
						window.location.href = returnUrl;
					}
				})
				.catch((error) => {
					setLoading(false);

					if (error?.response?.data?.lockedOut) {
						setError('Your account is locked out');
						return;
					}

					if (error?.response?.data?.code) {
						setError(error?.response?.data?.code[0]);
						return;
					}

					setError('Error occured. Unable to login');
				});
		}
	};

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='flex flex-col justify-center items-center pb-10'>
				<div className='font-bold text-3xl'>
					Two-factor Authentication
				</div>

				{/* <div className='pt-3'>
					Your login is protected with an authenticator app. Enter
					your authenticator code below.
				</div> */}
			</div>

			<div className='flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md'>
				<div className='mt-2'>
					<div className='pb-6 text-gray-500 text-center  text-sm'>
						Your login is protected with an authenticator app. Enter
						your authenticator code below.
					</div>
					<form onSubmit={handleSubmit(onLoginSubmit)}>
						<div className='flex flex-col mb-4'>
							<div className='relative'>
								<input
									type='text'
									placeholder='Enter MFA Code'
									maxLength={7}
									{...register('twoFactorCode', {
										required: true,
										minLength: 6,
										maxLength: 7,
									})}
									className='text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'
								/>
							</div>
						</div>

						<div className='flex flex-col mb-6'>
							<label className='inline-flex items-center cursor-pointer'>
								<input
									type='checkbox'
									className='form-checkbox border rounded text-blueGray-700 my-2 w-5 h-5 ease-linear transition-all duration-150 mr-2'
									{...register('rememberMachine')}
								/>
								Remember Machine
							</label>
						</div>

						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-start w-full -mt-1'>
								<span className='bg-white text-xs text-red-500'>
									{error}
								</span>
							</div>
						</div>

						<div className='flex w-full mt-10'>
							<button
								type='submit'
								disabled={!isValid}
								className={cn(
									'flex items-center text-white justify-center focus:outline-none  text-sm sm:text-base rounded-md py-2 w-full',
									{
										'bg-altia-blue hover:bg-blue-700':
											isValid,
										'bg-gray-400': !isValid,
									}
								)}
							>
								{loading ? (
									<Spinner size='sm' color='white' />
								) : null}
								<span className='mr-2'>Log In</span>
							</button>
						</div>
					</form>
				</div>

				<div className='flex justify-center items-center pt-4'>
					<Link to='/'>
						<a className='inline-flex items-center font-bold text-altia-red hover:text-red-700 text-center'>
							<span className='ml-2'>Go back to Login Page</span>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Login2fa;
