import Spinner from '../../components/spinner';
import { Link } from 'react-router-dom';
import ClientsTable from '../../components/clientsTable';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UserContext } from '../Home';
import { useContext, useState, useEffect } from 'react';

export default function Admin() {
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		axios
			.get(`/api/admin/clients`)
			.then((response) => {
				setLoading(false);
				setClients(response.data);
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Error loading apps');
			});
	}, []);

	const user: any = useContext(UserContext);
	return (
		<>
			<div className='px-4 sm:px-6 md:px-8 py-4 w-full'>
				{user?.claims && !loading ? (
					<>
						<>
							<Link
								to={`/home/admin/new_client`}
								className='flex w-44 items-center text-white justify-center focus:outline-none rounded-md py-2 px-4 bg-indigo-600 hover:bg-indigo-700 mb-8'
							>
								Add Application
							</Link>
						</>
						<ClientsTable data={clients}></ClientsTable>
					</>
				) : (
					<div className='flex items-center'>
						<Spinner size='lg' color='blue' /> Loading clients
					</div>
				)}
			</div>
		</>
	);
}
