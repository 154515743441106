export default function YourResources() {
	const resources = [
		{
			launchUrl: 'https://legal.altiacloud.com',
			name: 'Trust Center',
			icon: '/logo192.png',
		},
		{
			launchUrl: 'https://altiaintel.com/support',
			name: 'Altia Support',
			icon: '/logo192.png',
		},
		{
			launchUrl: 'https://altiaintel.com',
			name: 'Altia Website',
			icon: '/logo192.png',
		},
	];
	return (
		<div className='px-4 py-4 max-w-2xl'>
			<div className='uppercase text-xl font-semibold'>Resources</div>

			{resources.length > 0 ? (
				<div className='flex flex-wrap justify-center py-8'>
					<div className='grid grid-cols-2 gap-x-10 md:grid-cols-5 md:gap-x-10 lg:gap-x-32 gap-y-20'>
						{resources.map((x) => (
							<div key={x.name} className='flex flex-col '>
								<a
									href={x.launchUrl}
									target='_blank'
									className='shadow-xl rounded-3xl hover:shadow-2xl w-24 h-24'
									rel='noreferrer'
								>
									<div key={x.name} className='text-center'>
										<img
											src={x.icon}
											className='hover:cursor-pointer  w-full align-middle rounded-2xl object-cover'
											alt={x.name}
										/>
									</div>
								</a>
								<a
									href={x.launchUrl}
									target='_blank'
									className='hover:underline whitespace-nowrap text-gray-800 pt-3'
									rel='noreferrer'
								>
									{x.name}
								</a>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className='flex py-8'>No products found!</div>
			)}
		</div>
	);
}
