import Spinner from '../../components/spinner';
import ConnectedApps from '../../components/connectedapps';
import YourResources from '../../components/yourresources';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UserContext } from '../../containers/Home';
import { useContext, useState, useEffect } from 'react';

export default function ConnectedAppsHome() {
	const [apps, setApps] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		axios
			.get(`/api/user/connectedapps`)
			.then((response) => {
				setLoading(false);
				setApps(response.data);
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Error loading apps');
			});
	}, []);

	const user: any = useContext(UserContext);
	return (
		<>
			<div className='px-4 sm:px-6 md:px-8 py-4'>
				<YourResources />
				{user?.claims && !loading ? (
					<ConnectedApps apps={apps} />
				) : (
					<div className='flex items-center'>
						<Spinner size='lg' color='blue' /> Loading your
						products
					</div>
				)}
			</div>
		</>
	);
}
