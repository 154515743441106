import { Link } from 'react-router-dom';
import Header from '../components/header';

function Login() {
	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-50'>
			<Header />

			<div className='flex flex-col bg-white shadow-lg px-4 sm:px-6 md:px-8 lg:px-10 py-8 mt-12 rounded-lg w-full max-w-xl'>
				<div className='mt-2 text-left'>
					<div className='pb-4 font-bold text-3xl'>
						Account created
					</div>
					<div className='pb-3 text-gray-500 text-sm'>
						<p>
							You're almost there. A verification email has been
							sent to the address supplied. Please check your
							email (and junk) and follow the instructions to
							finalise the accounts creation.
						</p>
					</div>
					<div className=''>
						<Link to='/'>
							<a className='inline-flex items-center font-bold text-altia-red hover:text-red-700 text-center'>
								Return to Altia Identity
							</a>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
