export default function ExternalLogin({ action }) {
	const loginExternal = (provider: string) => {
		const query = new URLSearchParams(window.location.search);
		const returnUrl = query.get('ReturnUrl') || '/home';
		const stripeSessionId = query.get('stripe_session_id');
		const product = query.get('product');
		const betaProduct = query.get('betaProduct');
		const email = query.get('email');

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// dev code
			if (betaProduct) {
				console.log('Redirect to External login for Beta program');
				window.location.href = `https://localhost:5001/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
					returnUrl
				)}&betaProduct=${encodeURIComponent(
					betaProduct
				)}&email=${encodeURIComponent(email)}`;
			} else {
				window.location.href = !stripeSessionId
					? `https://localhost:5001/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
							returnUrl
					  )}`
					: `https://localhost:5001/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
							returnUrl
					  )}&stripeSessionId=${stripeSessionId}&pjroduct=${encodeURIComponent(
							product
					  )}`;
			}
		} else {
			if (betaProduct) {
				console.log('Redirect to External login for Beta program');
				window.location.href = `/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
					returnUrl
				)}&betaProduct=${encodeURIComponent(
					betaProduct
				)}&email=${encodeURIComponent(email)}`;
			} else {
				window.location.href = !stripeSessionId
					? `/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
							returnUrl
					  )}`
					: `/api/auth/ExtenalLogin?scheme=${provider}&returnUrl=${encodeURIComponent(
							returnUrl
					  )}&stripeSessionId=${stripeSessionId}&product=${encodeURIComponent(
							product
					  )}`;
			}
		}
	};

	return (
		<>
			<div className=' text-gray-500 text-sm'>
				By clicking 'Sign Up with Microsoft', 'Sign Up with Google', or
				'Sign Up' you have read and agree to{' '}
				<a
					href='https://legal.altiacloud.com/legal/current/msa/'
					target='_blank'
					rel='noreferrer'
					className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-xs'
				>
					<span className=''>Altia's Master Services Agreement</span>
				</a>{' '}
				and Agreement Reference Material all available at{' '}
				<a
					href='https://legal.altiacloud.com/'
					target='_blank'
					rel='noreferrer'
					className='inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline text-xs'
				>
					<span className=''>Altia Trust Center</span>
				</a>
			</div>

			<div className='flex justify-between space-x-2'>
				<button
					className='relative mt-8 border rounded-md py-2 text-sm text-gray-500  font-semibold hover:bg-gray-100 flex justify-center w-60 items-center space-x-1'
					onClick={() => loginExternal('Google')}
				>
					<svg
						className='h-6 w-6 text-center mr-2'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 256 262'
					>
						<path
							fill='#4285F4'
							d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
						/>
						<path
							fill='#34A853'
							d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
						/>
						<path
							fill='#FBBC05'
							d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
						/>
						<path
							fill='#EB4335'
							d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
						/>
					</svg>
					{action} with Google
				</button>
				<button
					className='relative mt-8 border rounded-md py-2 text-sm text-gray-500  font-semibold hover:bg-gray-100  flex justify-center w-60 items-center space-x-1'
					onClick={() => loginExternal('Microsoft')}
				>
					<svg
						className='h-6 w-6 text-center mr-2'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 23 23'
					>
						<path fill='#f35325' d='M1 1h10v10H1z' />
						<path fill='#81bc06' d='M12 1h10v10H12z' />
						<path fill='#05a6f0' d='M1 12h10v10H1z' />
						<path fill='#ffba08' d='M12 12h10v10H12z' />
					</svg>
					{action} with Microsoft
				</button>
			</div>
		</>
	);
}
