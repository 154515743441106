import Login from './containers/Login';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Home from './containers/Home';
import Signup from './containers/Signup';
import CreateAccount from './containers/CreateAccount';
import SignupBeta from './containers/SignupBeta';
import EmailConfirmationRequired from './containers/EmailConfirmationRequired';
import ForgotPassword from './containers/ForgotPassword';
import ResendVerification from './containers/ResendVerification';
import ResetPassword from './containers/ResetPassword';
import Login2fa from './containers/Login2fa';

import HomeIndex from './containers/home/index';
import ConnectedApps from './containers/home/connectedapps';
import Admin from './containers/home/admin';
import ClientEdit from './containers/home/clientEdit';
import AddClient from './containers/home/addClient';
import Security from './containers/home/security';
import Enable2fa from './containers/home/enable2fa';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<>
			<ToastContainer />
			<Router>
				<Routes>
					<Route path='/home' element={<Home />}>
						<Route index element={<HomeIndex />} />
						<Route path='security' element={<Security />} />
						<Route path='apps' element={<ConnectedApps />} />
						<Route path='admin' element={<Admin />} />
						<Route path='admin/clients/:id' element={<ClientEdit />} />
						<Route path='admin/new_client' element={<AddClient />} />
						<Route path='index' element={<HomeIndex />} />
						<Route path='security/setup2fa' element={<Enable2fa />} />
					</Route>
					<Route path='/signup' element={<Signup />} />
					<Route path='/createaccount' element={<CreateAccount />} />
					<Route path='/signupbeta' element={<SignupBeta />} />
					<Route path='/signin2fa' element={<Login2fa />} />
					<Route
						path='/confirmemail'
						element={<EmailConfirmationRequired />}
					/>
					<Route
						path='/forgotpassword'
						element={<ForgotPassword />}
					/>
					<Route
						path='/resendverification'
						element={<ResendVerification />}
					/>
					<Route path='/resetpassword' element={<ResetPassword />} />
					<Route path='/' element={<Login />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
