import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SaveButton from './saveButton';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function ResetPasswordForm({ data }) {
	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);
	const formSchema = Yup.object().shape({
		oldPassword: Yup.string()
			.required('Old Password is mandatory'),
		newPassword: Yup.string()
			.required('New Password is mandatory')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi,
				'Min 8 characters, 1 letter, 1 number and 1 special character'
			),
		confirmPassword: Yup.string()
			.required('Confirm New Password is mandatory')
			.oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	const onSave = (data: any) => {
		setSaving(true);
		axios
			.put(`/api/user/Password`, {
				...data,
			})
			.then((response: any) => {
				setSaving(false);
				setError('');
				toast('Password updated');
			})
			.catch((error) => {
				setSaving(false);

				if (error?.response?.data?.password) {
					setError(error?.response?.data?.password[0]);
					return;
				}
				setError('Error occured. Unable to update password');
			});
	};

	return (
		<div>
			<div className='pb-8 text-left text-lg font-semibold'>
				Change your password
			</div>
			<form onSubmit={handleSubmit(onSave)}>
				<div className='flex flex-col mb-4'>
					<label
						htmlFor='password'
						className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600'
					>
						Current Password:
					</label>
					<div
						className={
							'relative group ' +
							((errors as any)?.oldPassword ? 'isInvalid' : '')
						}
					>
						<input
							type='password'
							{...register('oldPassword', {
								required: true,
							})}
							className='form-field'
						/>
					</div>
				</div>

				{(errors as any)?.oldPassword?.message && (
					<div className='relative bg-gray-300'>
						<div className='absolute left-0 top-0 flex justify-end w-full -mt-3'>
							<span className='bg-white text-xs text-altia-red'>
								{(errors as any)?.oldPassword?.message}
							</span>
						</div>
					</div>
				)}

				<div className='flex flex-col mb-4'>
					<label
						htmlFor='password'
						className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600'
					>
						New Password:
					</label>
					<div
						className={
							'relative group ' +
							((errors as any)?.newPassword ? 'isInvalid' : '')
						}
					>
						<input
							type='password'
							{...register('newPassword', {
								required: true,
							})}
							className='form-field'
						/>
					</div>
				</div>

				{(errors as any)?.newPassword?.message && (
					<div className='relative bg-gray-300'>
						<div className='absolute left-0 top-0 flex justify-end w-full -mt-3'>
							<span className='bg-white text-xs text-altia-red'>
								{(errors as any)?.newPassword?.message}
							</span>
						</div>
					</div>
				)}

				<div className='flex flex-col mb-4'>
					<label
						htmlFor='password'
						className='mb-1 text-xs sm:text-sm tracking-wide text-gray-600'
					>
						Confirm New Password:
					</label>
					<div
						className={
							'relative group ' +
							((errors as any)?.confirmPassword
								? 'isInvalid'
								: '')
						}
					>
						<input
							type='password'
							{...register('confirmPassword', {
								required: true,
							})}
							className='form-field'
						/>
					</div>
				</div>

				{(errors as any)?.confirmPassword?.message && (
					<div className='relative bg-gray-300'>
						<div className='absolute left-0 top-0 flex justify-end w-full -mt-3'>
							<span className='bg-white text-xs text-altia-red'>
								{(errors as any)?.confirmPassword?.message}
							</span>
						</div>
					</div>
				)}

				<div className='relative bg-gray-300 mt-5'>
					<div className='absolute left-0 top-0 flex justify-start w-full'>
						<span className='bg-white text-xs text-altia-red'>
							{error}
						</span>
					</div>
				</div>

				<div className='flex w-full mt-10 justify-start'>
					<SaveButton saving={saving} type='submit'>
						Save Changes
					</SaveButton>
				</div>
			</form>
		</div>
	);
}

export default ResetPasswordForm;
