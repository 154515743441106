import cn from 'classnames';
import PhoneNumberInput from '../components/phonenumberinput';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import ModalChangeEmail from "./modalchangeemail";
import {useState} from "react";

function UserForm({
	errors,
	register,
	error,
	showPassword,
	emailReadonly,
	control,
	showTimezone,
	showMobileVerifedStatus,
	mobileIsVerified,
  setValue,
}: any) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<ModalChangeEmail
				isOpen={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
			/>
			<div className='flex mb-8 justify-between'>
				<div
					className={
						'relative group flex w-9/12' +
						((errors as any)?.email ? 'isInvalid' : '')
					}
				>
					<input
						type='email'
						readOnly={emailReadonly}
						placeholder='Email Address'
						{...register('email', {})}
						className={cn('form-field', {
							'bg-gray-100': emailReadonly,
						})}
					/>
				</div>
				<div
					className="cursor-pointer px-2 ml-3 text-white font-medium bg-altia-blue rounded-lg w-3/12 justify-center flex items-center"
					onClick={() => setIsModalOpen(true)}
				>
					Change
				</div>
			</div>
			{(errors as any)?.email?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white text-xs text-red-500'>
							{(errors as any)?.email?.message}
						</span>
					</div>
				</div>
			)}
			<div className='flex space-x-2'>
				<div className='flex flex-col mb-8 w-1/2'>
					<div
						className={
							'w-full mb-3 relative group ' +
							((errors as any)?.firstName ? 'isInvalid' : '')
						}
					>
						<input
							type='text'
							{...register('firstName')}
							className='form-field'
							placeholder='First Name'
						/>
					</div>
					{(errors as any)?.firstName?.message && (
						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
								<span className='bg-white text-xs text-red-500'>
									{(errors as any)?.firstName?.message}
								</span>
							</div>
						</div>
					)}
				</div>

				<div className='flex flex-col mb-8 w-1/2'>
					<div
						className={
							'w-full mb-3 relative group ' +
							((errors as any)?.lastName ? 'isInvalid' : '')
						}
					>
						<input
							type='text'
							{...register('lastName')}
							className='form-field'
							placeholder='Last Name'
						/>
					</div>
					{(errors as any)?.lastName?.message && (
						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
								<span className='bg-white text-xs text-red-500'>
									{(errors as any)?.lastName?.message}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>

			{showPassword ? (
				<div className='flex space-x-2'>
					<div className='flex flex-col mb-8 w-1/2'>
						<div
							className={
								'w-full mb-3 relative group ' +
								((errors as any)?.password ? 'isInvalid' : '')
							}
						>
							<input
								type='password'
								placeholder='Password'
								{...register('password')}
								className='form-field'
							/>
						</div>
						{(errors as any)?.password?.message && (
							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
									<span className='bg-white text-xs text-red-500'>
										{(errors as any)?.password?.message}
									</span>
								</div>
							</div>
						)}
					</div>

					<div className='flex flex-col mb-8 w-1/2'>
						<div
							className={
								'w-full mb-3 relative group ' +
								((errors as any)?.confirmPassword
									? 'isInvalid'
									: '')
							}
						>
							<input
								type='password'
								placeholder='Confirm Password'
								{...register('confirmPassword')}
								className='form-field'
							/>
						</div>

						{(errors as any)?.confirmPassword?.message && (
							<div className='relative bg-gray-300'>
								<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
									<span className='bg-white text-xs text-red-500'>
										{
											(errors as any)?.confirmPassword
												?.message
										}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			) : null}
			<div className='flex flex-col mb-8'>
				<div
					className={
						'relative group ' +
						((errors as any)?.mobileNumber ? 'isInvalid' : '')
					}
				>
					<PhoneNumberInput 
						control={control} 
						name='mobileNumber'
						onCountryChange={(country) => console.log(`Country changed to ${country}`)}
					/>
					{showMobileVerifedStatus && mobileIsVerified && (
						<CheckCircleIcon
							className='text-green-600 inline h-6 w-6 mr-2 absolute -mt-8 right-0'
							title='Mobile number is verified'
					/>
					)}
					{showMobileVerifedStatus && !mobileIsVerified && (
						<ExclamationCircleIcon
							className='text-red-600 inline h-6 w-6 mr-2 absolute -mt-8 right-0'
							title='Mobile number is not verified'
						/>
					)}
				</div>
			</div>
			{(errors as any)?.mobileNumber?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white text-xs text-red-500'>
							{(errors as any)?.mobileNumber?.message}
						</span>
					</div>
				</div>
			)}
			<div className='flex flex-col mb-8'>
				<div
					className={
						'relative group ' +
						((errors as any)?.workUnit ? 'isInvalid' : '')
					}
				>
					<input
						type='text'
						{...register('workUnit')}
						className='form-field'
						placeholder='Department Name'
					/>
				</div>
			</div>
			{showTimezone ? (
				<div className='flex flex-col mb-8'>
					<div
						className={
							'relative group ' +
							((errors as any)?.userTimezone ? 'isInvalid' : '')
						}
					>
						<select
							{...register('userTimezone')}
							className='form-field appearance-none form-select
												border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
							aria-label='Default select example'
							placeholder='Timezone'
						>
							<option value='' selected>
								-- Select Timezone --
							</option>
							{moment.tz.names().map((x) => (
								<option key={x} value={x}>
									{x}
								</option>
							))}
						</select>
					</div>
				</div>
			) : null}
			{(errors as any)?.workUnit?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white text-xs text-red-500'>
							{(errors as any)?.workUnit?.message}
						</span>
					</div>
				</div>
			)}
			<div className='relative mb-8 h-px bg-gray-300'>
				<div className='absolute left-0 top-0 flex justify-center w-full -mt-2'>
					<span className='bg-white px-4 text-xs text-gray-500 '>
						Additional Informations
					</span>
				</div>
			</div>
			<div className='flex flex-col mb-8'>
				<div
					className={
						'relative group ' +
						((errors as any)?.company ? 'isInvalid' : '')
					}
				>
					<input
						type='text'
						{...register('company')}
						className='form-field'
						placeholder='Company Name'
					/>
				</div>
			</div>
			{(errors as any)?.company?.message && (
				<div className='relative bg-gray-300'>
					<div className='absolute left-0 top-0 flex justify-end w-full -mt-6'>
						<span className='bg-white text-xs text-red-500'>
							{(errors as any)?.company?.message}
						</span>
					</div>
				</div>
			)}
			<div className='flex space-x-2'>
				<div className='flex flex-col mb-8 w-1/2'>
					<div
						className={
							'w-full mb-3 relative group ' +
							((errors as any)?.position ? 'isInvalid' : '')
						}
					>
						<input
							type='text'
							{...register('position')}
							className='form-field'
							placeholder='Position'
						/>
					</div>

					{(errors as any)?.position?.message && (
						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
								<span className='bg-white text-xs text-red-500'>
									{(errors as any)?.position?.message}
								</span>
							</div>
						</div>
					)}
				</div>

				<div className='flex flex-col mb-8 w-1/2'>
					<div
						className={
							'w-full mb-3 relative group ' +
							((errors as any)?.employeeId ? 'isInvalid' : '')
						}
					>
						<input
							type='text'
							{...register('employeeId')}
							className='form-field'
							placeholder='Employee ID'
						/>
					</div>
					{(errors as any)?.employeeId?.message && (
						<div className='relative bg-gray-300'>
							<div className='absolute left-0 top-0 flex justify-end w-full -mt-1'>
								<span className='bg-white text-xs text-red-500'>
									{(errors as any)?.employeeId?.message}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='relative bg-gray-300'>
				<div className='absolute left-0 top-0 flex justify-start w-full -mt-6'>
					<span className='bg-white text-red-500'>{error}</span>
				</div>
			</div>
		</>
	);
}

export default UserForm;